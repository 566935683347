import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  selectSystem,
  fetchAnalytics,
  fetchVacationRequests,
} from 'redux/modules/duck-admin';
import _ from 'lodash';
import moment from 'moment';
import Pluralize from 'react-pluralize';
import { Header } from 'common/Header';
import LoaderPage from 'common/LoaderPage';
import AnalyticsCard from 'common/AnalyticsCard';
import BarChart from 'common/d3/BarChart';
import PieChart from 'common/d3/PieChart';
import HzBarChart from 'common/d3/HzBarChart';
import VacationChart from 'common/d3/VacationChart';
import UsagePieChart from 'common/d3/UsagePieChart';
import ParcelUsage from 'common/d3/ParcelUsage';
import SystemActivity from 'common/d3/SystemActivity';
import ProgressBar from 'common/d3/ProgressBar';
import PickupLineChart from 'common/d3/PickupLineChart';
import colors from 'utils/colors';
import { baseFont, boldFont } from 'utils/fonts';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

// grid has max of 2 columns and min of 1 column and centers all cards
// max width prevents having more than 2 columns
const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
  max-width: 1000px;
  width: 100%;
  justify-items: center;
  @media (max-width: 580px) {
    justify-items: flex-start;
  }
`;

const NA = styled.div`
  min-height: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: 30px;
  color: ${colors.primary_blue};
  font-family: ${boldFont};
`;

const BarWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  & > div {
    flex: 1 0 33% !important;
  }
`;

const ProgressBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -10px;
`;

const ParcelInfo = styled.span`
  margin-top: -30px;
`;

const PieLabelWrapper = styled.div`
  font-size: 12px;
  margin-top: 50%;
`;

const PieLabel = styled.div`
  display: flex;
  flex-flow: row wrap;
  text-align: left;
  margin-bottom: 70px;
  span:before {
    content: '';
    display: inline-flex;
    width: 10px;
    height: 10px;
    margin-right: 5px;
  }
`;

const ColorBox = styled.span`
  margin-right: 20px;
  display: flex;
  width: 100%;
  :before {
    background: ${(props) => props.color};
  }
`;

const Title = styled.h1`
  font-size: 24px;
  color: ${colors.base_text3};
  font-family: ${baseFont};
  line-height: 22px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 50px;
  align-self: flex-start;
`;

const PackageCount = styled.span`
  position: absolute;
  margin-top: 250px;
  color: ${colors.base_text3};
  font-family: ${baseFont};
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
`;

const NAWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 250px;
`;

const NATitle = styled.h1`
  color: #5c7fa7;
  font-family: ${baseFont};
  font-size: 24px;
  font-weight: bold;
  line-height: 22px;
  text-align: center;
  margin-top: 0;
`;

const NASub = styled.span`
  color: #15234a;
  font-family: ${baseFont};
  font-size: 18px;
  font-weight: 300;
  line-height: 22px;
  text-align: center;
`;

const InviteButton = styled(Link)`
  margin-top: 9px;
  padding: 7px 0;
  width: 155px;
  border-radius: 2px;
  background-color: #3e3e58;
  color: #f6f7f9;
  font-family: 'Swiss721BTRegular';
  font-size: 14px;
  letter-spacing: 0.54px;
  line-height: 16px;
  text-align: center;
`;

class AdminDash extends Component {
  constructor(props) {
    super(props);
    this.state = {
      capacityTab: 'Total',
      vacationTab: 'thisweek',
      courierUsageTab: 'thisweek',
      systemUsageTab: 'thisweek',
      systemActivityTab: 'thisweek',
      usageTab: 'Total',
      usageHeaderTab: 'Door Usage',
      pickupLineChartTab: 'thisweek',
    };
    this.changeCapacityTab = this.changeCapacityTab.bind(this);
    this.changeVacationTab = this.changeVacationTab.bind(this);
    this.changeCourierUsageTab = this.changeCourierUsageTab.bind(this);
    this.changeSystemUsageTab = this.changeSystemUsageTab.bind(this);
    this.changeSystemActivityTab = this.changeSystemActivityTab.bind(this);
    this.changeUsageTab = this.changeUsageTab.bind(this);
    this.changeUsageHeaderTab = this.changeUsageHeaderTab.bind(this);
    this.changePickupLineChartTab = this.changePickupLineChartTab.bind(this);
  }

  componentDidMount() {
    if (localStorage.user_token && this.props.selectedSystem) {
      this.props.fetchAnalytics(
        this.props.selectedSystem.system._id,
        this.props.selectedSystem.system.timezone,
        this.props.history
      );
      this.props.fetchVacationRequests(
        this.props.selectedSystem.system._id,
        this.props.history
      );
    }
  }

  changeCapacityTab(tab) {
    if (tab !== this.state.capacityTab) {
      this.setState({ capacityTab: tab });
    }
  }

  changeVacationTab(tab) {
    if (tab !== this.state.vacationTab) {
      this.setState({ vacationTab: tab });
    }
  }

  changeCourierUsageTab(tab) {
    if (tab !== this.state.courierUsageTab) {
      this.setState({ courierUsageTab: tab });
    }
  }

  changeSystemUsageTab(tab) {
    if (tab !== this.state.systemUsageTab) {
      this.setState({ systemUsageTab: tab });
    }
  }

  changeSystemActivityTab(tab) {
    if (tab !== this.state.changeSystemActivityTab) {
      this.setState({ systemActivityTab: tab });
    }
  }

  changeUsageTab(tab) {
    if (tab !== this.state.usageTab) {
      this.setState({ usageTab: tab });
    }
  }

  changeUsageHeaderTab(tab) {
    if (tab !== this.state.usageHeaderTab) {
      this.setState({ usageHeaderTab: tab });
    }
  }

  changePickupLineChartTab(tab) {
    if (tab !== this.state.pickupLineChartTab) {
      this.setState({ pickupLineChartTab: tab });
    }
  }

  renderAnalytics() {
    const {
      capacityTab,
      systemUsageTab,
      usageTab,
      usageHeaderTab,
      systemActivityTab,
      pickupLineChartTab,
    } = this.state;
    const {
      topFiveUsers,
      capacity,
      lockerUsage,
      averagePickupTime,
      vacationRequestsOverTime,
      recipientInfo,
      topFourCourier,
      systemActivity,
      approvedVacations,
      courierUsage,
      systemUsage,
      pickedUpParcels,
      pickupLineChart,
    } = this.props.analytics;

    const colorScale = [
      'darkgray',
      'lightslategray',
      'gray',
      'lightgray',
      'dimgray',
    ];

    return (
      <CardWrapper>
        <AnalyticsCard
          tab1="Total"
          tab2="By Size"
          tab={capacityTab}
          changeTab={this.changeCapacityTab}
          title="Capacity"
          total_warning={capacity.total >= 70}
          all_warning={
            capacity.sm >= 70 && capacity.med >= 70 && capacity.lg >= 70
          }
          color={capacity.total >= 70 ? 'warning_red' : null}
          disabled={capacity.totalCount == 0}
        >
          {capacity.totalCount == 0 ? (
            <NAWrapper>
              <NATitle>0 Packages</NATitle>
            </NAWrapper>
          ) : capacityTab === 'Total' ? (
            <ProgressBarWrapper>
              <ProgressBar
                data={capacity.total}
                parcelCount={capacity.totalCount}
              />
              {capacity.expiringParcels > 0 ? (
                <Pluralize
                  singular="parcel is expiring soon"
                  plural="parcels are expiring soon"
                  count={capacity.expiringParcels}
                  style={{
                    marginTop: '-30px',
                    color:
                      capacity.total >= 70
                        ? colors.warning_red
                        : colors.primary_blue,
                  }}
                />
              ) : null}
            </ProgressBarWrapper>
          ) : (
            <BarWrapper>
              <ProgressBar
                label="Small"
                data={capacity.sm}
                barLabel={[`${capacity.smCount} pkg`]}
                size={true}
              />
              <ProgressBar
                label="Medium"
                data={capacity.med}
                barLabel={[`${capacity.medCount} pkg`]}
                size={true}
              />
              <ProgressBar
                label="Large"
                data={capacity.lg}
                barLabel={[`${capacity.lgCount} pkg`]}
                size={true}
              />
              {capacity.xlOrOversizedExists && (
                <span
                  style={{
                    display: 'flex',
                    height: '150px',
                    marginTop: '20px',
                  }}
                >
                  <ProgressBar
                    label="X-Large"
                    data={capacity.xl}
                    barLabel={[`${capacity.xlCount} pkg`]}
                    size={true}
                  />
                  <ProgressBar
                    label="Oversized"
                    data={capacity.oversized}
                    barLabel={[`${capacity.oversizedCount} pkg`]}
                    size={true}
                  />
                </span>
              )}
            </BarWrapper>
          )}
          {capacity.totalCount > 0 ? (
            <PackageCount>{capacity.totalCount} packages</PackageCount>
          ) : null}
        </AnalyticsCard>
        <AnalyticsCard
          title="System Activity"
          changeTab={this.changeSystemActivityTab}
        >
          <SystemActivity data={systemActivity[this.state.systemActivityTab]} />
        </AnalyticsCard>
        <AnalyticsCard changeTab={this.changeSystemUsageTab} title="Usage">
          <ParcelUsage
            data={systemUsage[this.state.systemUsageTab]}
            tab={this.state.systemUsageTab}
          />
        </AnalyticsCard>
        <AnalyticsCard
          changeTab={this.changePickupLineChartTab}
          title="Average Pickup Time"
          dateRange={
            this.state.pickupLineChartTab === 'thisweek'
              ? pickedUpParcels.weekRange
              : pickedUpParcels.monthRange
          }
        >
          <PickupLineChart
            data={pickedUpParcels[this.state.pickupLineChartTab]}
            tab={this.state.pickupLineChartTab}
            dateRange={
              this.state.pickupLineChartTab === 'thisweek'
                ? pickedUpParcels.weekRange
                : pickedUpParcels.monthRange
            }
          />
        </AnalyticsCard>
      </CardWrapper>
    );
  }

  render() {
    const { analytics, analytics_loading } = this.props;
    return (
      <Header style={{ alignItems: 'flex-start' }}>
        <Title>Dashboard</Title>

        <Wrapper>
          {analytics && !_.isEmpty(analytics) && !analytics_loading ? (
            this.renderAnalytics()
          ) : analytics && _.isEmpty(analytics) ? (
            <NA>Not enough packages to display data.</NA>
          ) : (
            <div style={{ marginTop: '200px', height: '200px' }}>
              <LoaderPage color="accent1" height="50%" width="50%" />
            </div>
          )}
        </Wrapper>
      </Header>
    );
  }
}

function mapStateToProps({ admin }) {
  const {
    systems,
    analytics_loading,
    approvedVacations,
    selectSystemIndex,
    selectedSystem,
    analytics,
    vacations,
  } = admin;
  return {
    systems,
    approvedVacations,
    analytics_loading,
    selectSystemIndex,
    selectedSystem,
    analytics,
    vacations,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { selectSystem, fetchAnalytics, fetchVacationRequests },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminDash);
