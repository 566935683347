import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import ReactModal from 'react-modal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  fetchProfile,
  deactivateUser,
  editUser,
  clearProfile,
} from 'redux/modules/duck-admin';
import { noValuesChangedAlert } from 'redux/modules/duck-auth';
import { reduxForm, Field } from 'redux-form';
import _ from 'lodash';
import colors from 'utils/colors';

import 'react-phone-number-input/style.css';

import { editProfileFields } from 'common/forms/formFields';
import renderUpgradeUser from 'common/forms/renderUpgradeUser';
import renderField from 'common/forms/renderField';
import LoaderPage from 'common/LoaderPage';
import LongButton from 'common/buttons/longButton.js';
import Spinner from 'common/Spinner';
import ErrorMessage from 'common/Error';
import Title from 'common/forms/FormTitle';
import { Header } from 'common/Header';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

import { mobile } from 'utils/media';
import { settingsValidate as validate } from 'utils/validations';
import { isAdmin, isOwner } from 'utils/adminCheck';

const Loader = styled(Spinner)`
  margin: 0 auto;
`;

const FormWrapper = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const FieldWrapper = styled.div`
  display: flex;
  margin-top: -15px;
  flex-direction: column;
  width: 100%;
  align-items: center;
  ${mobile(css`
    align-items: left;
  `)};
`;

const Row = styled.div`
  max-width: 750px;
  display: flex;
  justify-content: center;
  ${mobile(css`
    flex-direction: column;
    width: 100%;
    div {
      width: 100%;
    }
  `)};
`;

const Confirmation = styled.div`
  font-size: 20;
  ${mobile(
    css`
      font-size: 16px;
      span {
        font-size: 16px;
      }
    `
  )};
`;

const GobackButton = styled(LongButton)`
  width: 125px;
  margin: auto 5px;
  font-size: 16px;
  transition: all 0.3s ease-in-out;
`;

const DeactivateButton = styled(LongButton)`
  width: 125px;
  margin: auto 5px;
  font-size: 16px;
`;

const ModifyArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  text-align: center;
  margin: auto 0;
`;

const DeactivateEditButton = styled(LongButton)`
  margin-top: 25px;
`;

const customPhoneInput = styled.div`
  width: 275px;

  img {
    border: none;
    height: 14px;
    width: 27px;
  }

  button {
    border: none;
  }

  input {
    background: transparent;
    height: 60px
    width: 100%;
    color: ${colors.base_text3};
    font-size: 16px;
    border-style: none
    ::placeholder {
      font-size: 16px;
      color: ${colors.primary_gray};
      opacity: 0.75;
      transition: opacity 150ms ease-in-out;
    }

    ${(p) =>
      p.formtype !== 'register'
        ? mobile(
            css`
              padding-left: 5px;
              text-align: left;
            `
          )
        : null};
    + span {
      width: 80%;
      &:after {
        margin-top: -1.5px;
        z-index: 999;
        display: block;
        content: '';
        border-bottom: 2px solid
          ${(p) =>
            p.formtype === 'landing' || p.formtype === 'register'
              ? colors.base_text3
              : colors.base_text3};
        transform: scaleX(0);
        transition: transform 250ms ease-in-out;
      }
    }
  }

  input:focus {
    outline: none;
    + span {
      &:after {
        transform: scaleX(1.01);
      }
    }
    &::placeholder {
      opacity: 0.75;
    }
  }

  &::placeholder {
    font-size: 16px;
    color: ${colors.primary_gray};
    opacity: 0.75;
    transition: opacity 150ms ease-in-out;
  }
`;

class Edit extends Component {
  state = {
    phone: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
    this.toggleModal = this.toggleModal.bind(this);
  }

  componentWillUnmount() {
    this.props.clearProfile(); // clear confimation of settings changed
  }

  componentWillMount() {
    ReactModal.setAppElement('body');
    const { user_id } = this.props.match.params;
    this.props.fetchProfile(user_id, this.props.history);
  }

  toggleModal() {
    this.setState({ showModal: !this.state.showModal, pin: null });
  }

  renderUpdateAccess() {
    const { profile, systems } = this.props;
    // systems - systems that current_user has access to
    return _.map(systems, (current_user_access, index) => {
      // matching_index - loop through profile user's access to find if any system ids are matching with the current user's system id
      const matching_index = profile.access.findIndex(
        (profile_access) =>
          profile_access._system === current_user_access.system._id
      );

      // level - determine if profile user has ADMIN rights to same system if matching_index was found
      const level =
        matching_index >= 0 ? profile.access[matching_index].level : 0;

      return (
        <Field
          name={`System:${current_user_access.system._id}`}
          key={index}
          label={current_user_access.system.nickname}
          component={renderUpgradeUser}
          level={level}
        />
      );
    });
  }

  renderFields() {
    return _.map(editProfileFields, (field, index) => {
      if (field.length) {
        const row = field;
        return (
          <Row key={index}>
            {_.map(row, (fieldInRow, index) => {
              return (
                <Field
                  key={index}
                  component={renderField}
                  type={fieldInRow.type}
                  label={fieldInRow.label}
                  name={fieldInRow.name}
                  placeholder={fieldInRow.label}
                  row="true"
                />
              );
            })}
          </Row>
        );
      } else {
        return (
          <Row key={index}>
            <Field
              component={renderField}
              key={field.name}
              type={field.type}
              label={field.label}
              name={field.name}
              placeholder={field.label}
              formtype="EditUser"
            />
          </Row>
        );
      }
    });
  }
  render() {
    // const input = document.querySelector('.rrui__input-field');
    // if (input) {
    //   const parent = input.parentNode;
    //   const wrapper = document.createElement('div');
    //   wrapper.style.setProperty('width', '100%', 'important');

    //   parent.replaceChild(wrapper, input);
    //   wrapper.appendChild(input);
    //   wrapper.appendChild(document.createElement('span'));
    // }
    const { err, loading, profile, history, current_user } = this.props;

    return profile && !loading ? (
      <Header style={{ marginTop: '25px' }}>
        <Title>
          {profile.first_name} {profile.last_name}
        </Title>
        <FormWrapper
          onSubmit={this.props.handleSubmit(() => {
            const dataWithoutEmail = _.omit(
              this.props.editProfileForm.values,
              'email'
            );

            if (this.props.dirty) {
              this.props.editUser({
                _id: profile._id,
                ...dataWithoutEmail,
              });
            } else {
              this.props.noValuesChangedAlert();
            }
          })}
        >
          <FieldWrapper>
            {this.renderFields()}

            <PhoneInput
              placeholder="Phone Number"
              country="US"
              value={this.state.phone ? this.state.phone : profile.phone}
              onChange={(value) => this.props.change('phone', value)}
              style={{ marginTop: '25px' }}
              containerComponent={customPhoneInput}
            />
            {current_user &&
            isOwner(current_user.access) &&
            current_user._id !== profile._id ? (
              <FieldWrapper>
                <p>Permissions</p>
                {this.renderUpdateAccess()}
              </FieldWrapper>
            ) : null}
          </FieldWrapper>
          {err ? (
            <ErrorMessage
              style={{ width: '100%', maxWidth: '400px', margin: '25px 0 0 0' }}
            >
              {err.message}
            </ErrorMessage>
          ) : null}
          <LongButton
            style={{ overflow: 'hidden' }}
            textColor="white"
            backgroundColor="primary_blue"
          >
            {loading ? <Loader /> : 'UPDATE'}
          </LongButton>
          {current_user &&
          ((isAdmin(current_user.access) && !isAdmin(profile.access)) ||
            (isOwner(current_user.access) && !isOwner(profile.access))) &&
          current_user._id !== profile._id ? (
            <DeactivateEditButton
              textColor="white"
              backgroundColor="warning_red"
              type="button"
              onClick={() => this.toggleModal()}
            >
              DEACTIVATE
            </DeactivateEditButton>
          ) : null}

          <ReactModal
            className="Modal"
            overlayClassName="Overlay"
            closeTimeoutMS={150}
            isOpen={this.state.showModal}
            onRequestClose={this.toggleModal}
            shouldCloseOnOverlayClick
            shouldCloseOnEsc
          >
            <Confirmation>
              <h2 style={{ textAlign: 'center', margin: '0 0 25px 0' }}>
                Confirm to deactivate user:{' '}
                <p>
                  <span>{profile.email}</span>
                </p>
              </h2>
            </Confirmation>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <ModifyArea>
                <DeactivateButton
                  textColor="white"
                  backgroundColor="warning_red"
                  type="button"
                  onClick={() => {
                    this.toggleModal();
                    this.props.deactivateUser(profile._id, history);
                  }}
                >
                  DEACTIVATE
                </DeactivateButton>
                <GobackButton
                  textColor="white"
                  backgroundColor="primary_blue"
                  type="button"
                  onClick={() => this.toggleModal()}
                >
                  GO BACK
                </GobackButton>
              </ModifyArea>
            </div>
          </ReactModal>
        </FormWrapper>
      </Header>
    ) : (
      <LoaderPage color="accent1" />
    );
  }
}

function mapStateToProps({ admin, form, auth }) {
  const current_user = auth.user;
  const { editProfileForm } = form;
  const { profile, error, loading, systems } = admin;
  let initialValues = null;
  if (profile) {
    initialValues = {
      first_name: profile.first_name,
      last_name: profile.last_name,
      email: profile.email,
      secondary_email: profile.secondary_email || '',
      phone: profile.phone,
    };
  }

  return {
    initialValues,
    profile,
    current_user,
    loading,
    err: error,
    editProfileForm,
    systems,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchProfile,
      deactivateUser,
      editUser,
      clearProfile,
      noValuesChangedAlert,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: 'editProfileForm',
    touchOnBlur: false,
  })(Edit)
);
