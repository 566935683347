import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import ReactModal from 'react-modal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import Tooltip from 'rc-tooltip';
import _ from 'lodash';
import {
  clearRemoveAccessPopUp,
  fetchUsers,
  inviteUser,
  changeSort,
  changeFilter,
  deleteUser,
  changeRole,
  removeAccess,
  clearError,
  clearProfile,
} from 'redux/modules/duck-admin';
import {
  fetchAdminUserLog,
  changeLogSort,
  resetLogSort,
} from 'redux/modules/duck-parcels';
import {
  changeRoleNoti,
  noEmailNoti,
  removeUserNoti,
  cancelInviteNoti,
} from 'utils/notiOptions';
import { success, warning } from 'react-notification-system-redux';

import ManageUser from './ManageUser';

import LogTable from 'common/LogTable';
import LoaderPage from 'common/LoaderPage';
import LongButton from 'common/buttons/longButton';
import SvgIcon from 'common/icons/SvgIcon';

import colors from 'utils/colors';
import { baseFont, boldFont } from 'utils/fonts';
import Spinner from 'common/Spinner';
import { belowFourHeight, belowfour } from 'utils/media';
import GetTableSize from 'utils/getTableSize';
import Title from 'common/forms/FormTitle';

const Loader = styled(Spinner)`
  margin: 0 auto;
`;

const Wrapper = styled.div`
  width: auto;
  box-sizing: border-box;
  overflow-y: auto;
`;

const NA = styled.div`
  min-height: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: 30px;
  color: ${colors.primary_blue};
  font-family: ${boldFont};
`;

const InputWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 150px;
`;

const FilterBar = styled.input`
  float: left;
  width: 60%;
  height: 28px;
  padding-left: 40px;
  font-size: 18px;
  line-height: 21px;
  font-family: ${baseFont};
  border: none;
  border-bottom: 1px solid rgba(51, 51, 51, 0.3);
  transition: all 350ms ease-in-out;
  &::placeholder {
    font-size: 18px;
    line-height: 21px;
    font-family: ${baseFont};
    color: ${colors.primary_gray};
    opacity: 0.35;
    transition: opacity 150ms ease-in-out;
  }
  &:focus {
    outline: none;
    + span {
      &:after {
        width: 100%;
      }
    }
    &::placeholder {
      opacity: 0.1;
    }
  }
  + span {
    float: left;
    width: calc(60% + 40px);
    &:after {
      margin-top: -1px;
      z-index: 999;
      display: block;
      content: '';
      border-bottom: 2px solid ${colors.base_text3};
      width: 0;
      transition: width 250ms ease-in-out;
    }
  }
`;

const InviteModalButton = styled(LongButton)`
  float: right;
  width: 155px;
  height: 33px;
  font-size: 14px;
  margin-top: -30px;
  @media (max-width: 580px) {
    float: left;
    margin-top: 0;
  }
`;

const ModifyArea = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 265px;
`;

const SelectWrapper = styled.div`
  width: 100%;
`;

const SelectWrap = styled.div`
  position: relative;
  :after {
    content: '';
    position: absolute;
    top: 50%;
    right: 10px;
    margin-top: -3px;
    border-width: 6px;
    border-style: solid;
    border-color: ${colors.base_text3} transparent transparent;
  }
`;

const SelectField = styled.select`
  width: 100%;
  padding: 0 !important;
  font-size: 14px;
  font-family: ${baseFont};
  color: #333333;
  background: transparent;
  border-color: transparent;
  text-transform: uppercase;
  cursor: pointer;
`;

const OptionSelect = styled.option`
  color: ${colors.action2};
`;

const PageTitle = styled.h1`
  font-size: 24px;
  color: ${colors.base_text3};
  font-family: ${baseFont};
  line-height: 22px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 50px;
  align-self: flex-start;
`;

const ReinviteButton = styled.button`
  cursor: pointer;
  font-family: ${baseFont};
  color: ${colors.base_text3};
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.51px;
  border: none;
  background: transparent;
`;

const CloseButton = styled.span`
  fontfamily: Arial;
  lineheight: 15px;
  cursor: pointer;
  color: ${(p) => colors[p.color] || colors.base_text3};
  fontweight: 300;
  width: 14px;
  height: 14px;
  fontsize: 17px;
`;

const PaginationButton = styled.button`
  color: ${colors.base_text3};
  font-family: ${baseFont};
  font-size: 14px;
  letter-spacing: 0.71px;
  line-height: 18px;
  border: none;
`;

class Manage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      showInvitationModal: false,
      showEditModal: false,
      showRemoveModal: false,
      targetUserEmail: '',
      search: '',
      user: null,
      created: false,
      confirmRemoveUser: false,
      initialHover: false,
    };

    this.handleFilter = this.handleFilter.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.resendInvite = this.resendInvite.bind(this);
    this.resetTable = this.resetTable.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.toggleEditModal = this.toggleEditModal.bind(this);
    this.closeRemoveModal = this.closeRemoveModal.bind(this);
    this.closeConfirmationModal = this.closeConfirmationModal.bind(this);
    this.confirmRemove = this.confirmRemove.bind(this);
    this.removeUserAccess = this.removeUserAccess.bind(this);
  }

  resetTable() {
    this.props.changeSort('email', 'first_name', this.props.ascending);
  }

  componentDidMount() {
    ReactModal.setAppElement('body');
    if (localStorage.user_token && this.props.selectedSystem) {
      this.props.fetchUsers(
        this.props.selectedSystem.system._id,
        this.props.history
      );
    }
  }

  componentWillUnmount() {
    this.props.changeFilter('');
  }

  componentDidUpdate() {
    if (this.props.profile || this.props.editProfile) {
      if (localStorage.user_token && this.props.selectedSystem) {
        this.props.fetchUsers(
          this.props.selectedSystem.system._id,
          this.props.history
        );
        this.toggleEditModal();
        this.props.clearProfile();
      }
    }
    if (!this.state.showRemoveModal) {
      if (this.props.removePopUp) {
        this.setState({ showRemoveModal: true });
        this.props.clearRemoveAccessPopUp();
      }
    }
  }

  handleFilter(e) {
    const input_v = e.currentTarget.value.toLowerCase();
    this.setState({ search: input_v });
  }

  clearFilter() {
    this.props.changeFilter('');
    this.filter.value = '';
    this.setState({ search: '' });
  }

  resendInvite(emails) {
    this.props.inviteUser({
      emails,
      company: this.props.current_user._company,
    });
  }

  openLogs(user_id) {
    this.props.fetchAdminUserLog(
      user_id,
      this.props.selectedSystem.system._id,
      this.props.history
    );
    this.setState({
      showModal: !this.state.showModal,
    });
  }

  confirmRemove() {
    this.setState({
      confirmRemoveUser: true,
    });
  }

  closeModal() {
    this.props.resetLogSort();
    this.setState({
      showModal: !this.state.showModal,
    });
  }

  toggleEditModal(user) {
    this.props.clearError();
    if (user) {
      this.setState({
        showEditModal: !this.state.showEditModal,
        user: user,
      });
    } else {
      this.setState({
        showEditModal: !this.state.showEditModal,
        user: null,
      });
    }
  }

  closeRemoveModal() {
    this.setState({
      showRemoveModal: !this.state.showRemoveModal,
      confirmRemoveUser: false,
    });
  }

  closeConfirmationModal() {
    this.setState({
      showConfirmationModal: !this.state.showConfirmationModal,
    });
  }

  removeUserAccess() {
    const { history, removeUser } = this.props;
    this.props.removeAccess(removeUser.accessId, removeUser.userName, history);
    this.closeRemoveModal();
  }

  renderButtons(user, deleting) {
    return (
      <ModifyArea>
        <ReinviteButton
          onClick={(e) => {
            e.currentTarget.textContent = 'SENT';
            e.currentTarget.style.color = '#90A1B5';
            e.currentTarget.setAttribute('disabled', 'disabled');
            this.resendInvite(user.email);
          }}
        >
          REINVITE
        </ReinviteButton>
        <CloseButton
          color="action2"
          onClick={() => {
            this.props.cancelInvitePopUp(user.email, user._id);
          }}
        >
          x
        </CloseButton>
      </ModifyArea>
    );
  }

  render() {
    const {
      managers_table_loading,
      loading,
      parcels,
      logs,
      changeLogSort,
      history,
      current_user,
      match,
      users,
      selectedSystem,
    } = this.props;

    // Set the table size without blank rows.
    let filterUsers = users;

    const { pageStart, pageSizeArray } = GetTableSize(users.length);

    // Search feature
    if (this.state.search) {
      filterUsers = users.filter((row) => {
        // Filter by email
        if (row.email) {
          if (row.email.includes(this.state.search)) {
            return row;
          }
        }

        // Filter by full name
        if (row.first_name) {
          // Skip pending user by checking first name existence
          const fullName = `${row.first_name} ${row.last_name}`.toLowerCase();
          if (fullName.includes(this.state.search)) {
            return row;
          }
        }

        if (row.employee_id.includes(this.state.search)) {
          return row;
        }

        // Filter by status
        if (
          ('pending'.includes(this.state.search) &&
            row.status === 'reactivate') ||
          row.status.includes(this.state.search)
        ) {
          return row;
        }

        if ('active'.includes(this.state.search) && row.status === 'active') {
          return row;
        }

        // Filter by role
        if (
          'corporate admin'.includes(this.state.search) &&
          row.user_role === 1
        ) {
          return row;
        }

        if ('store admin'.includes(this.state.search) && row.user_role === 2) {
          return row;
        }

        if ('employee'.includes(this.state.search) && row.user_role === 3) {
          return row;
        }
      });
    }

    const columns = [
      {
        Header: <span>First Name</span>,

        headerClassName: 'table-header',
        width: 150,
        className: 'table-cell',
        Cell: ({ row }) => {
          const user = row._original;
          const firstName = user.first_name;

          if (user.first_name) {
            return firstName;
          } else {
            return '';
          }
        },
        accessor: 'first_name',
        sortMethod: (a, b, desc) => {
          if (desc) {
            if (a && !b) {
              return 1;
            }

            if (!a && b) {
              return -1;
            }

            if (!a && !b) {
              return 0;
            }

            if (a && b && a[0] > b[0]) {
              return 1;
            }

            if (a && b && a[0] < b[0]) {
              return -1;
            }

            return 0;
          } else {
            if (a && !b) {
              return -1;
            }

            if (!a && b) {
              return 1;
            }

            if (!a && !b) {
              return 0;
            }

            if (a && b && a[0] > b[0]) {
              return 1;
            }

            if (a && b && a[0] < b[0]) {
              return -1;
            }

            return 0;
          }
        },
      },
      {
        Header: 'Last Name',
        headerClassName: 'table-header',
        width: 150,
        className: 'table-cell',
        Cell: ({ row }) => {
          const user = row._original;
          const lastName = user.last_name;

          if (user.last_name) {
            return lastName;
          } else {
            return '';
          }
        },
        accessor: 'last_name',
        sortMethod: (a, b, desc) => {
          if (desc) {
            if (a && !b) {
              return 1;
            }

            if (!a && b) {
              return -1;
            }

            if (!a && !b) {
              return 0;
            }

            if (a && b && a[0] > b[0]) {
              return 1;
            }

            if (a && b && a[0] < b[0]) {
              return -1;
            }

            return 0;
          } else {
            if (a && !b) {
              return -1;
            }

            if (!a && b) {
              return 1;
            }

            if (!a && !b) {
              return 0;
            }

            if (a && b && a[0] > b[0]) {
              return 1;
            }

            if (a && b && a[0] < b[0]) {
              return -1;
            }

            return 0;
          }
        },
      },
      {
        Header: 'Employee ID',
        headerClassName: 'table-header',
        width: 150,
        className: 'table-cell',
        Cell: ({ row }) => {
          const user = row._original;
          if (user.employee_id) {
            return user.employee_id;
          } else {
            return '';
          }
        },
        accessor: 'phone',
      },
      {
        Header: 'Email (Optional)',
        headerClassName: 'table-header',
        className: 'table-cell',
        accessor: 'email',
        minWidth: 250,
        sortMethod: (a, b) => {
          if (a < b) {
            return -1;
          }

          if (a > b) {
            return 1;
          }

          return 0;
        },
      },
      {
        Header: 'Status',
        headerClassName: 'table-header',
        className: 'table-cell',
        Cell: ({ row }) => {
          const user = row._original;
          if (
            user.status === 'reactivate' ||
            user.status === 'verify' ||
            user.status === 'set_pin'
          ) {
            return 'PENDING';
          } else {
            return user.status ? user.status.toUpperCase() : null;
          }
        },
        accessor: 'status',
      },
      {
        Header: <span>Role</span>,
        headerClassName: 'table-header',
        className: 'table-cell table-col-timestamp',
        accessor: 'user_role',
        sortMethod: (a, b) => {
          if (a < b) {
            return -1;
          }

          if (a > b) {
            return 1;
          }
        },
        width: 200,
        Cell: ({ row }) => {
          const user = row._original;

          const { user_role } = user;

          // owner perspective - ability to change roles
          // and also user cannot edit own role
          if (this.props.selectedSystem.level === 1) {
            if (user_role !== 1 && user.status !== 'inactive') {
              const fullName = user.first_name
                ? `${user.first_name} ${user.last_name}`
                : user.email;

              return (
                <SelectWrapper>
                  <SelectWrap>
                    <SelectField
                      value={user_role}
                      onChange={(e) => {
                        let role = e.currentTarget.value;
                        e.currentTarget.value = user_role;
                        {
                          role !== 1 && !user.email
                            ? this.props.noEmailPopUp()
                            : this.props.changeRolePopUp(
                                fullName,
                                user.email,
                                role,
                                user._access
                              );
                        }
                      }}
                    >
                      <OptionSelect value={2}>Store Admin</OptionSelect>
                      <OptionSelect value={3}>Employee</OptionSelect>
                    </SelectField>
                  </SelectWrap>
                </SelectWrapper>
              );
            } else if (user.status === 'inactive') {
              return (
                <div>
                  <span>
                    <b>
                      {user_role === 3 ? 'Employee' : null}
                      {user_role === 2 ? 'Store Admin' : null}
                      {user_role === 1 ? 'Corporate Admin' : null}
                    </b>
                  </span>
                </div>
              );
            } else {
              return <b>CORPORATE ADMIN</b>;
            }
          } else {
            return (
              <Tooltip
                placement="leftTop"
                trigger={['hover']}
                overlayStyle={{
                  color: '#189BBF',
                }}
                overlay={
                  <div>
                    {user.user_role === 3 ? 'Drop off access only' : null}
                    {user.user_role === 2
                      ? 'Access to assigned system dashboards'
                      : null}
                    {user.user_role === 1
                      ? 'Access to all system dashboards within company'
                      : null}
                  </div>
                }
              >
                <div>
                  <span>
                    <b>
                      {user.user_role === 3 ? 'Employee' : null}
                      {user.user_role === 2 ? 'Store Admin' : null}
                      {user.user_role === 1 ? 'Corporate Admin' : null}
                    </b>
                  </span>
                </div>
              </Tooltip>
            );
            if (user.user_role == 3) {
              return 'Employee';
            } else if (user.user_role == 2) {
              return <b>Store Admin</b>;
            } else if (user.user_role == 1) {
              return <b style={{ color: 'red' }}>Corporate Admin</b>;
            }
          }
        },
      },
      {
        Header: 'Action',
        headerClassName: 'table-header',
        className: 'table-cell label-column',
        width: 200,
        accessor: 'action',
        Cell: ({ row }) => {
          const user = row._original;

          const active = user.status === 'active';

          if (
            // Current user is an Owner and can remove anyones access
            ((this.props.selectedSystem.level === 1 && active) ||
              // Current user is an Admin and can remove user access
              (this.props.selectedSystem.level === 2 &&
                active &&
                user.user_role === 3) ||
              (user.user_role === 2 && user.status !== 'pending')) &&
            // Current user isn't themselves
            current_user._id !== user._id
          ) {
            const fullName = `${user.first_name} ${user.last_name}`;
            return (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <ModifyArea style={{ alignSelf: 'start' }}>
                  <span
                    style={{
                      cursor: 'pointer',
                      fontSize: '12px',
                    }}
                    onClick={() => {
                      this.toggleEditModal(user);
                    }}
                  >
                    EDIT
                  </span>
                </ModifyArea>
                <ModifyArea style={{ marginLeft: '40px' }}>
                  <span
                    style={{
                      cursor: 'pointer',
                      color: colors.action2,
                      fontSize: '12px',
                      paddingLeft: '8px',
                      lineheight: '15px',
                    }}
                    onClick={() => {
                      this.props.removeUserPopUp(fullName, user._access);
                    }}
                  >
                    REMOVE
                  </span>
                </ModifyArea>
              </div>
            );
          } else if (
            user.status === 'pending' ||
            user.status === 'reactivate'
          ) {
            return this.renderButtons(user, loading);
          }

          return null;
        },
      },
    ];

    return this.props.selectedSystem ? (
      <div
        style={{
          width: '100%',
          padding: '15px',
          boxSizing: 'border-box',
        }}
      >
        <PageTitle>Manage Users</PageTitle>
        <InputWrapper>
          <SvgIcon
            height="18px"
            width="18px"
            fill={colors.base_text3}
            icon="search"
            viewBox="0 0 18 18"
            style={{
              position: 'absolute',
              float: 'left',
              paddingTop: '5px',
            }}
          />
          <FilterBar
            onChange={this.handleFilter}
            type="text"
            innerRef={(ref) => (this.filter = ref)}
            placeholder="Search People"
          />
          <span />
          <InviteModalButton
            backgroundColor="accent1"
            textColor="base2"
            onClick={() => this.toggleEditModal()}
          >
            Add +
          </InviteModalButton>
        </InputWrapper>
        <Wrapper>
          {managers_table_loading ? (
            <LoaderPage color="accent1" />
          ) : filterUsers.length === 0 && !managers_table_loading ? (
            <NA>No Users yet!</NA>
          ) : (
            <ReactTable
              data={filterUsers}
              columns={columns}
              noDataText=""
              className="table -highlight"
              defaultPageSize={pageStart}
              pageSizeOptions={pageSizeArray}
              defaultSorted={[{ id: 'email' }]}
              minRows={0}
              loading={managers_table_loading}
              loadingText="Loading..."
              resizable={false}
              rowsText={null}
              PreviousComponent={(props) => {
                return (
                  <div>
                    <PaginationButton
                      {...props}
                      style={{
                        color: props.disabled
                          ? colors.accent4
                          : colors.base_text3,
                      }}
                    >
                      PREV
                    </PaginationButton>
                  </div>
                );
              }}
              NextComponent={(props) => {
                return (
                  <PaginationButton
                    {...props}
                    style={{
                      color: props.disabled
                        ? colors.accent4
                        : colors.base_text3,
                    }}
                  >
                    NEXT
                  </PaginationButton>
                );
              }}
            />
          )}
        </Wrapper>
        <ReactModal
          className="Modal LogModal"
          overlayClassName="Overlay"
          closeTimeoutMS={150}
          isOpen={this.state.showModal}
          onRequestClose={this.closeModal}
          shouldCloseOnOverlayClick
          shouldCloseOnEsc
        >
          <LogTable
            parcels={parcels}
            log_loading={logs.log_loading}
            changeLogSort={changeLogSort}
          />
        </ReactModal>
        <ReactModal
          className="Modal EditModal"
          overlayClassName="Overlay"
          closeTimeoutMS={150}
          isOpen={this.state.showEditModal}
          onRequestClose={this.toggleEditModal}
          shouldCloseOnOverlayClick
          shouldCloseOnEsc
        >
          <CloseButton
            style={{
              position: 'absolute',
              top: '20px',
              right: '20px',
              fontSize: '30px',
            }}
            onClick={() => this.toggleEditModal()}
          >
            x
          </CloseButton>
          <ManageUser
            userEdit={this.state.user}
            currentRole={this.props.selectedSystem.level}
          />
        </ReactModal>
        <ReactModal
          className="Modal RemoveModal"
          overlayClassName="Overlay"
          closeTimeoutMS={150}
          isOpen={this.state.showRemoveModal}
          onRequestClose={this.closeRemoveModal}
          shouldCloseOnOverlayClick
          shouldCloseOnEsc
        >
          <CloseButton
            style={{
              position: 'absolute',
              top: '20px',
              right: '20px',
              fontSize: '30px',
            }}
            onClick={() => this.closeRemoveModal()}
          >
            x
          </CloseButton>

          {this.props.removeUser ? (
            !this.state.confirmRemoveUser ? (
              <div
                style={{
                  marginTop: '55px',
                  alignContent: 'center',
                  justifyContent: 'center',
                }}
              >
                <Title style={{ fontsize: 30 }}>
                  Are you sure you want to remove{' '}
                  {this.props.removeUser.userName}
                </Title>
                <div
                  style={{
                    alignItems: 'center',
                    fontSize: 18,
                    margin: 40,
                    color: '#083E66',
                  }}
                >
                  Removing this user means you no longer want him/her to access
                  the lock and permanently deactivate them from the system.
                </div>
                <LongButton
                  backgroundColor="accent1"
                  textColor="white"
                  style={{
                    width: '196px',
                    height: '44px',
                    margin: '30px',
                  }}
                  onClick={this.closeRemoveModal}
                >
                  {loading ? <Loader /> : 'Cancel'}
                </LongButton>
                <LongButton
                  backgroundColor="accent1"
                  textColor="white"
                  style={{
                    width: '196px',
                    height: '44px',
                    margin: '30px',
                  }}
                  onClick={this.confirmRemove}
                >
                  {loading ? <Loader /> : 'Yes, Confirm'}
                </LongButton>
              </div>
            ) : (
              <div
                style={{
                  marginTop: '55px',
                  alignContent: 'center',
                  justifyContent: 'center',
                }}
              >
                <Title style={{ fontsize: '30px', margin: '30px' }}>
                  Are you sure you want to remove{' '}
                  {this.props.removeUser.userName}
                </Title>
                <div
                  style={{
                    alignItems: 'center',
                    fontSize: 18,
                    margin: 40,
                    color: '#083E66',
                  }}
                >
                  <LongButton
                    backgroundColor="accent1"
                    textColor="white"
                    style={{
                      width: '196px',
                      height: '44px',
                      margin: '30px',
                    }}
                    onClick={this.closeRemoveModal}
                  >
                    {loading ? <Loader /> : 'Cancel'}
                  </LongButton>
                  <LongButton
                    backgroundColor="blue"
                    textColor="white"
                    style={{
                      width: '196px',
                      height: '44px',
                      margin: '30px',
                    }}
                    onClick={this.removeUserAccess}
                  >
                    {loading ? <Loader /> : 'Confirm'}
                  </LongButton>
                </div>
              </div>
            )
          ) : null}
        </ReactModal>
      </div>
    ) : (
      <Loader />
    );
  }
}

function mapStateToProps({ admin, auth, parcels }) {
  const current_user = auth.user;
  const {
    users,
    loading,
    profile,
    editProfile,
    removePopUp,
    removeUser,
    removedUser,
  } = admin;
  const { log_parcels } = parcels;
  const { managers_table_loading, ascending, sortBy, selectedSystem } = admin;
  return {
    removeUser,
    removedUser,
    loading,
    profile,
    editProfile,
    users,
    current_user,
    managers_table_loading,
    ascending,
    removePopUp,
    sortBy,
    parcels: log_parcels,
    logs: {
      log_loading: parcels.log_loading,
      log_user_id: parcels.log_user_id,
    },
    selectedSystem,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchUsers,
      inviteUser,
      changeSort,
      changeFilter,
      fetchAdminUserLog,
      changeLogSort,
      resetLogSort,
      deleteUser,
      changeRole,
      removeAccess,
      clearError,
      clearProfile,
      clearRemoveAccessPopUp,
      changeRolePopUp: (name, email, role, _access) =>
        success(changeRoleNoti(name, email, role, _access)),
      removeUserPopUp: (name, _access) =>
        warning(removeUserNoti(name, _access)),
      cancelInvitePopUp: (email, _user) =>
        warning(cancelInviteNoti(email, _user)),
      noEmailPopUp: () => warning(noEmailNoti()),
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Manage);
