import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';
import { Link, Prompt } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { reduxForm, Field } from 'redux-form';
import _ from 'lodash';
import {
  changeSettings,
  noValuesChangedAlert,
  fetchUser,
} from 'redux/modules/duck-auth';
import { userInfoSettings as validate } from 'utils/validations';
import 'react-phone-number-input/style.css';

import { Header } from 'common/Header';
import Title from 'common/forms/FormTitle';
import renderField from 'common/forms/renderField';
import Box from 'common/forms/FormBox';
import LongButton from 'common/buttons/longButton.js';
import Spinner from 'common/Spinner';
import renderSystemSetting from 'common/forms/renderSystemSetting';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { baseFont, boldFont } from 'utils/fonts';

import { mobile } from 'utils/media';
import colors from 'utils/colors';
import TimezoneDropdown from '../../common/forms/dropdownMenu';

const Loader = styled(Spinner)`
  margin: 0 auto;
`;

const FormWrapper = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  ${mobile(css`
    flex-direction: column;
    width: 100%;
    div {
      width: 100%;
    }
  `)};
`;

const ButtonWrapper = styled.div`
  width: 100%;
  margin: 25px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  ${mobile(css`
    flex-direction: column;
    align-items: center;
    align-content: center;
  `)};
`;

const ToolLink = styled(Link)`
  width: 25%;
  min-width: 173px;
  ${mobile(
    css`
      margin: 15px;
      width: 75%;
    `
  )};
`;

const ToolButton = styled(LongButton)`
  box-sizing: border-box;
  height: 34px;
  width: 173px;
  border: 1px solid #3e3e58;
  border-radius: 2px;

  color: #3e3e58;
  font-family: 'Swiss721BTRegular';
  font-size: 14px;
  letter-spacing: 0.54px;
  line-height: 16px;
  text-align: center;
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  width: 100%;
  align-items: left;
  ${mobile(css`
    align-items: left;
  `)};
`;

const SubTitle = styled.p`
  color: #083e66;
  font-size: 24px;
  font-weight: bold;
  line-height: 22px;
  text-align: left;
  margin: 50px 0 0 0;
`;

const UpdateButton = styled(LongButton)`
  height: 45px;
  width: 257px;
  color: #ffffff;
  font-family: ${baseFont};
  font-size: 15px;
  letter-spacing: 0.58px;
  line-height: 18px;
  text-align: center;
`;

const customPhoneInput = styled.div`
  width: 275px;
  margin-left: 50px;
  img {
    border: none;
    height: 14px;
    width: 27px;
  }

  button {
    border: none;
  }

  input {
    background: transparent;
    height: 60px
    width: 100%;
    color: ${colors.base_text3};
    font-size: 16px;
    border-style: none
    ::placeholder {
      font-size: 16px;
      color: ${colors.primary_gray};
      opacity: 0.75;
      transition: opacity 150ms ease-in-out;
    }

    ${(p) =>
      p.formtype !== 'register'
        ? mobile(
            css`
              padding-left: 5px;
              text-align: left;
            `
          )
        : null};
    + span {
      width: 80%;
      &:after {
        margin-top: -1.5px;
        z-index: 999;
        display: block;
        content: '';
        border-bottom: 2px solid
          ${(p) =>
            p.formtype === 'landing' || p.formtype === 'register'
              ? colors.base_text3
              : colors.base_text3};
        transform: scaleX(0);
        transition: transform 250ms ease-in-out;
      }
    }
  }

  input:focus {
    outline: none;
    + span {
      &:after {
        transform: scaleX(1.01);
      }
    }
    &::placeholder {
      opacity: 0.75;
    }
  }

  &::placeholder {
    font-size: 16px;
    color: ${colors.primary_gray};
    opacity: 0.75;
    transition: opacity 150ms ease-in-out;
  }
`;

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: '',
      time_zone: '',
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.user && this.props.user._id) {
      // this.props.fetchUser(this.props.user._id, this.props.history); TODO: figure out line of code and why it caused nonstop rendering
      // this could be if a user is linked to the settings page or they refresh on it
    }

    // add underline animation for phone number inputComponent
    // const input = document.querySelector('.rrui__input-field');
    // const parent = input.parentNode;
    // const wrapper = document.createElement('div');
    // wrapper.style.setProperty('width', '100%', 'important');

    // parent.replaceChild(wrapper, input);
    // wrapper.appendChild(input);
    // wrapper.appendChild(document.createElement('span'));
  }

  checkChanges(values) {
    const { user } = this.props;
    let changedValues = {};
    for (const field in values) {
      if (
        values[field] !== undefined &&
        user[field] !== undefined &&
        !_.isEqual(values[field], user[field])
      ) {
        changedValues[field] = values[field];
      }
    }
    return changedValues;
  }

  handleSubmit() {
    this.setState({ isBlocking: false });
    if (this.props.settingsForm.userSettingsForm.syncErrors) {
      return;
    }
    const changedValues = this.checkChanges(
      this.props.settingsForm.userSettingsForm.values
    );
    if (
      Object.keys(changedValues).length > 0 &&
      changedValues.constructor === Object
    ) {
      this.props.changeSettings(changedValues, this.props.history);
    } else {
      this.props.noValuesChangedAlert();
    }
  }

  render() {
    const { loading, handleSubmit, user } = this.props;
    const { selectedOption } = this.state;

    return (
      <Header style={{ marginTop: '25px' }}>
        <FormWrapper onSubmit={handleSubmit(this.handleSubmit)}>
          <Prompt
            when={this.props.dirty}
            message={(location) =>
              `Are you sure you want to leave without save?`
            }
          />
          <FieldWrapper>
            <SubTitle style={{ margin: 0 }}>Contact</SubTitle>
            <Box>
              <Row>
                <Field
                  name="first_name"
                  label="First Name"
                  component={renderField}
                  formtype="userSettings"
                  row="true"
                  defaultValue={this.props.user.first_name}
                />
                <Field
                  name="last_name"
                  label="Last Name"
                  component={renderField}
                  formtype="userSettings"
                  row="true"
                  defaultValue={this.props.user.last_name}
                />
              </Row>
              <Row>
                <Field
                  name="email"
                  label="Primary Email"
                  component={renderField}
                  formtype="userSettings"
                  row="true"
                  defaultValue={this.props.user.email}
                />
                <Field
                  name="secondary_email"
                  label="Secondary Email"
                  placeholder="Optional"
                  component={renderField}
                  formtype="userSettings"
                  row="true"
                />
              </Row>
              <Row>
                <PhoneInput
                  formtype="register"
                  placeholder="Phone Number"
                  country="US"
                  onChange={(value) => this.props.change('phone', value)}
                  value={
                    user && !this.state.phone ? user.phone : this.state.phone
                  }
                  style={{ paddingBottom: '20px' }}
                  containerComponent={customPhoneInput}
                />
              </Row>
              <Row>
                <Field
                  name="timezone"
                  label="Timezone"
                  defaultValueTime={user.timezone}
                  component={renderSystemSetting}
                />
              </Row>
            </Box>
          </FieldWrapper>
          <UpdateButton
            textColor="white"
            backgroundColor="accent1"
            type="submit"
            style={{ overflow: 'hidden' }}
          >
            {loading ? <Loader /> : 'UPDATE'}
          </UpdateButton>
        </FormWrapper>
        <FieldWrapper>
          <SubTitle>Settings</SubTitle>
          <Box>
            <ButtonWrapper>
              <ToolLink to="/admin/dashboard/settings/password">
                <ToolButton
                  textColor="accent1"
                  backgroundColor="white"
                  type="button"
                >
                  CHANGE PASSWORD
                </ToolButton>
              </ToolLink>
              <ToolLink to="/admin/dashboard/settings/reset_pin">
                <ToolButton
                  textColor="accent1"
                  backgroundColor="white"
                  type="button"
                >
                  RESET PIN
                </ToolButton>
              </ToolLink>
            </ButtonWrapper>
          </Box>
        </FieldWrapper>
      </Header>
    );
  }
}

function mapStateToProps({ auth, form }) {
  let initialValues = null;
  const { user, loading, error } = auth;
  if (user) {
    initialValues = {
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      secondary_email: user.secondary_email || '',
      phone: user.phone,
    };
  }
  return { loading, err: error, settingsForm: form, initialValues, user };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      changeSettings,
      noValuesChangedAlert,
      fetchUser,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: 'userSettingsForm',
    touchOnBlur: false,
    enableReinitialize: true,
  })(Settings)
);
