import { axiosInstance as axios } from 'redux/client';
import { success, warning } from 'react-notification-system-redux';
import { isValidNumber } from 'libphonenumber-js';
import moment from 'moment';
import {
  settingsSuccess,
  forgotPasswordSuccessMsg,
  forgotPasswordFailMsg,
  passwordSuccessMsg,
  tokenExpires,
  databaseError,
  noUser,
  unsubscribeSuccess,
  noValuesChanged,
  vacationHoldReq,
  vacationHoldReqFail,
  vacationHoldExceedLimit,
  loginError,
  emptyPhone,
  invalidPhone,
  emptyTimezone,
  resendVerificationSuccess,
  verifyUserSuccess,
  verifyUserFail,
  setPinNotiSuccess,
} from 'utils/notiOptions';
import { isOwner } from 'utils/adminCheck';

// Action Constants
const NAV_TOGGLE = 'casegoods-PL-web/auth/NAV_TOGGLE';
const LOGIN_ADMIN_REQUEST = 'casegoods-PL-web/auth/LOGIN_ADMIN_REQUEST';
const LOGIN_ADMIN_SUCCESS = 'casegoods-PL-web/auth/LOGIN_ADMIN_SUCCESS';
const LOGIN_ADMIN_FAIL = 'casegoods-PL-web/auth/LOGIN_ADMIN_FAIL';
const LOGIN_REQUEST = 'casegoods-PL-web/auth/LOGIN_REQUEST';
const LOGIN_SUCCESS = 'casegoods-PL-web/auth/LOGIN_SUCCESS';
const LOGIN_FAIL = 'casegoods-PL-web/auth/LOGIN_FAIL';
const REGISTER_REQUEST = 'casegoods-PL-web/auth/REGISTER_REQUEST';
const REGISTER_SUCCESS = 'casegoods-PL-web/auth/REGISTER_SUCCESS';
const REGISTER_FAIL = 'casegoods-PL-web/auth/REGISTER_FAIL';
const REGISTER_LINK_ACCESSED_VIA_GEN_LINK =
  'casegoods-PL-web/auth/REGISTER_LINK_ACCESSED_VIA_GEN_LINK';
const SET_PIN_REQUEST = 'casegoods-PL-web/auth/SET_PIN_REQUEST';
const SET_PIN_SUCCESS = 'casegoods-PL-web/auth/SET_PIN_SUCCESS';
const SET_PIN_FAIL = 'casegoods-PL-web/auth/SET_PIN_FAIL';
const FORGOT_PASSWORD_REQUEST = 'casegoods-PL-web/auth/FORGOT_PASSWORD_REQUEST';
const FORGOT_PASSWORD_SUCCESS = 'casegoods-PL-web/auth/FORGOT_PASSWORD_SUCCESS';
const FORGOT_PASSWORD_FAIL = 'casegoods-PL-web/auth/FORGOT_PASSWORD_FAIL';
const RESET_LINK_REQUEST = 'casegoods-PL-web/auth/RESET_LINK_REQUEST';
const RESET_LINK_SUCCESS = 'casegoods-PL-web/auth/RESET_LINK_SUCCESS';
const RESET_LINK_FAIL = 'casegoods-PL-web/auth/RESET_LINK_FAIL';
const RESET_PASSWORD_REQUEST = 'casegoods-PL-web/auth/RESET_PASSWORD_REQUEST';
const RESET_PASSWORD_SUCCESS = 'casegoods-PL-web/auth/RESET_PASSWORD_SUCCESS';
const RESET_PASSWORD_FAIL = 'casegoods-PL-web/auth/RESET_PASSWORD_FAIL';
export const LOGOUT = 'casegoods-PL-web/auth/LOGOUT';
const CLEAR_MESSAGES = 'casegoods-PL-web/auth/CLEAR_MESSAGES';
const INITIAL_LOAD = 'casegoods-PL-web/auth/INITIAL_LOAD';
const ADMIN_FROM_TOKEN_SUCCESS =
  'casegoods-PL-web/auth/ADMIN_FROM_TOKEN_SUCCESS';
const USER_FROM_TOKEN_REQUEST = 'casegoods-PL-web/auth/USER_FROM_TOKEN_REQUEST';
const USER_FROM_TOKEN_SUCCESS = 'casegoods-PL-web/auth/USER_FROM_TOKEN_SUCCESS';
const USER_FROM_TOKEN_FAILURE = 'casegoods-PL-web/auth/USER_FROM_TOKEN_FAILURE';
const CHANGE_SETTINGS_REQ = 'casegoods-PL-web/auth/CHANGE_SETTINGS_REQ';
const CHANGE_SETTINGS_SUCCESS = 'casegoods-PL-web/auth/CHANGE_SETTINGS_SUCCESS';
const CHANGE_SETTINGS_FAIL = 'casegoods-PL-web/auth/CHANGE_SETTINGS_FAIL';
const REGISTER_USER_INVITATION_REQUEST =
  'casegoods-PL-web/auth/REGISTER_USER_INVITATION_REQUEST';
const REGISTER_USER_INVITATION_SUCCESS =
  'casegoods-PL-web/auth/REGISTER_USER_INVITATION_SUCCESS';
const REGISTER_USER_INVITATION_FAIL =
  'casegoods-PL-web/auth/REGISTER_USER_INVITATION_FAIL';
const USER_INVITE_CREATE_USER_REQUEST =
  'casegoods-PL-web/auth/USER_INVITE_CREATE_USER_REQUEST';
const USER_INVITE_CREATE_USER_SUCCESS =
  'casegoods-PL-web/auth/USER_INVITE_CREATE_USER_SUCCESS';
const USER_INVITE_CREATE_USER_FAIL =
  'casegoods-PL-web/auth/USER_INVITE_CREATE_USER_FAIL';
const REGISTER_LINK_REQUEST = 'casegoods-PL-web/auth/REGISTER_LINK_REQUEST';
const REGISTER_LINK_SUCCESS = 'casegoods-PL-web/auth/REGISTER_LINK_SUCCESS';
const REGISTER_LINK_FAIL = 'casegoods-PL-web/auth/REGISTER_LINK_FAIL';
const FETCH_USER_REQUEST = 'casegoods-PL-web/auth/FETCH_USER_REQUEST';
const FETCH_USER_SUCCESS = 'casegoods-PL-web/auth/FETCH_USER_SUCCESS';
const FETCH_USER_FAIL = 'casegoods-PL-web/auth/FETCH_USER_FAIL';
const UNSUBSCRIBE_USER_REQUEST =
  'casegoods-PL-web/auth/UNSUBSCRIBE_USER_REQUEST';
const UNSUBSCRIBE_USER_SUCCESS =
  'casegoods-PL-web/auth/UNSUBSCRIBE_USER_SUCCESS';
const UNSUBSCRIBE_USER_FAIL = 'casegoods-PL-web/auth/UNSUBSCRIBE_USER_FAIL';
const FETCH_DIRECTORY_REQUEST = 'casegoods-PL-web/auth/FETCH_DIRECTORY_REQUEST';
const FETCH_DIRECTORY_SUCCESS = 'casegoods-PL-web/auth/FETCH_DIRECTORY_SUCCESS';
const FETCH_DIRECTORY_FAIL = 'casegoods-PL-web/auth/FETCH_DIRECTORY_FAIL';
const VACATION_HOLD_REQUEST_REQUEST =
  'casegoods-PL-web/auth/VACATION_HOLD_REQUEST_REQUEST';
const VACATION_HOLD_REQUEST_SUCCESS =
  'casegoods-PL-web/auth/VACATION_HOLD_REQUEST_SUCCESS';
const VACATION_HOLD_REQUEST_FAIL =
  'casegoods-PL-web/auth/VACATION_HOLD_REQUEST_FAIL';
const VACATION_LIST_REQUEST_SUCCESS =
  'casegoods-PL-web/auth/VACATION_LIST_REQUEST_SUCCESS';
const VACATION_LIST_REQUEST_FAIL =
  'casegood-PL-web/auth/VACATION_LIST_REQUEST_FAIL';

const initialState = {
  user_invitation: false,
  user_invitation_loading: false,
  user_invitation_error: false,
  invite_user_create: false,
  invite_user_create_loading: false,
  invite_user_create_error: false,
  accessedViaGenLink: false,
  loading: false, // Loading boolean for pages / spinners
  isAdmin: false,
  isOwner: false,
  isAuthenticated: false, // valid user logged in
  error: null, // error messages
  initialLoad: true,
  user: null,
  validToken: false, // valid register link
  phone: null, // phone number to display on current verification step
  time_zone: null, // time zone during the registration form field that can be modified within the system settings within Dashboard
  isNavOpen: null,
  directory: [],
  forgot_success: false, // indicate that forgot password email was sent to a valid email
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case NAV_TOGGLE:
      return {
        ...state,
        isNavOpen: action.data === 'toggle' ? !state.isNavOpen : action.data,
      };
    case LOGIN_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
        isAuthenticated: false,
        isAdmin: false,
        isOwner: false,
        error: null,
      };
    case LOGIN_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        isAdmin: true,
        isOwner: action.isOwner,
        isAuthenticated: true,
        user: action.data.user,
        error: null,
      };
    case LOGIN_ADMIN_FAIL:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        isAdmin: false,
        isOwner: false,
        user: null,
        error: action.error,
      };
    case LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        isAuthenticated: false,
        error: null,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        user: action.data.user,
        error: null,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        user: null,
        error: action.error,
      };
    case REGISTER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case REGISTER_USER_INVITATION_REQUEST:
      return {
        ...state,
        user_invitation: false,
        user_invitation_loading: true,
        user_invitation_error: false,
      };
    case REGISTER_USER_INVITATION_SUCCESS:
      return {
        ...state,
        user_invitation: true,
        user_invitation_loading: false,
        user_invitation_error: false,
      };
    case REGISTER_USER_INVITATION_FAIL:
      return {
        ...state,
        user_invitation: false,
        user_invitation_loading: false,
        user_invitation_error: true,
      };
    case USER_INVITE_CREATE_USER_REQUEST:
      return {
        ...state,
        invite_user_create: false,
        invite_user_create_loading: true,
        invite_user_create_error: false,
      };
    case USER_INVITE_CREATE_USER_SUCCESS:
      return {
        ...state,
        invite_user_create: true,
        invite_user_create_loading: false,
        invite_user_create_error: false,
      };
    case USER_INVITE_CREATE_USER_FAIL:
      return {
        ...state,
        invite_user_create: false,
        invite_user_create_loading: false,
        invite_user_create_error: true,
      };
    case SET_PIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SET_PIN_SUCCESS:
      return {
        ...state,
        loading: false,
        // Taking this out since it was causing an infinite loop
        // user: action.data.user,
        // isAuthenticated: true,
        error: null,
      };
    case SET_PIN_FAIL:
      return {
        ...state,
        loading: false,
        user: null,
        isAuthenticated: false,
      };
    case FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        forgot_success: false,
        loading: true,
        error: null,
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgot_success: true,
        loading: false,
        error: null,
      };
    case FORGOT_PASSWORD_FAIL:
      return {
        ...state,
        forgot_success: false,
        loading: false,
        error: action.error,
      };
    case RESET_LINK_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case RESET_LINK_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case RESET_LINK_FAIL:
      return {
        ...state,
        loading: false,
      };
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case RESET_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case LOGOUT:
      return { ...initialState, initialLoad: false };
    case CLEAR_MESSAGES:
      return {
        ...state,
        error: null,
      };
    case INITIAL_LOAD:
      return {
        ...state,
        initialLoad: false,
      };
    case ADMIN_FROM_TOKEN_SUCCESS:
      return {
        ...state,
        initialLoad: false,
        isAdmin: true,
        isOwner: action.isOwner,
        isAuthenticated: true,
        user: action.data.user,
        error: null,
      };
    case USER_FROM_TOKEN_REQUEST:
      return {
        ...state,
        initialLoad: true,
        isAuthenticated: false,
        error: null,
      };
    case USER_FROM_TOKEN_SUCCESS:
      return {
        ...state,
        initialLoad: false,
        isAuthenticated: true,
        user: action.data.user,
      };
    case USER_FROM_TOKEN_FAILURE:
      return {
        ...state,
        initialLoad: false,
        isAuthenticated: false,
        error: null,
      };
    case CHANGE_SETTINGS_REQ:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CHANGE_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.data.user,
      };
    case CHANGE_SETTINGS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case REGISTER_LINK_REQUEST:
      return {
        ...state,
        loading: true,
        phone: null,
        error: null,
        accessedViaGenLink: false,
      };
    case REGISTER_LINK_SUCCESS:
      return {
        ...state,
        validToken: true,
        loading: false,
        phone: action.data.phone,
        error: null,
      };
    case REGISTER_LINK_FAIL:
      return {
        ...state,
        validToken: false,
        phone: null,
        loading: false,
        user: null,
        accessedViaGenLink: false,
      };
    case REGISTER_LINK_ACCESSED_VIA_GEN_LINK:
      return {
        ...state,
        accessedViaGenLink: true,
      };
    case FETCH_USER_REQUEST:
      return {
        ...state,
        // loading: true,
        // user: {}
      };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: Object.assign({}, action.data.user),
      };
    case FETCH_USER_FAIL:
      return {
        ...state,
        loading: false,
      };
    case UNSUBSCRIBE_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UNSUBSCRIBE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: null,
      };
    case UNSUBSCRIBE_USER_FAIL:
      return {
        ...state,
        loading: false,
      };
    case FETCH_DIRECTORY_REQUEST:
      return {
        ...state,
        loading: true,
        directory: [],
      };
    case FETCH_DIRECTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        directory: action.data.directory,
      };
    case FETCH_DIRECTORY_FAIL:
      return {
        ...state,
        loading: false,
        directory: [],
      };
    case VACATION_HOLD_REQUEST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case VACATION_HOLD_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case VACATION_HOLD_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
      };
    case VACATION_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case VACATION_LIST_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}

// Actions
export function loginAdminReq() {
  return {
    type: LOGIN_ADMIN_REQUEST,
  };
}

export function loginAdminSuccess(data) {
  return {
    type: LOGIN_ADMIN_SUCCESS,
    isOwner: data.isOwner,
    data,
  };
}

export function loginAdminFail(error) {
  return {
    type: LOGIN_ADMIN_FAIL,
    error,
  };
}

export function loginUserReq() {
  return {
    type: LOGIN_REQUEST,
  };
}

export function loginUserSuccess(data) {
  return {
    type: LOGIN_SUCCESS,
    data,
  };
}

export function loginUserFail(error) {
  return {
    type: LOGIN_FAIL,
    error,
  };
}

export function registerUserReq() {
  return {
    type: REGISTER_REQUEST,
  };
}

export function registerUserSuccess(data) {
  return {
    type: REGISTER_SUCCESS,
    data,
  };
}

export function registerUserFail(error) {
  return {
    type: REGISTER_FAIL,
    error,
  };
}

export function setPinReq() {
  return {
    type: SET_PIN_REQUEST,
  };
}

export function setPinSuccess(data) {
  return {
    type: SET_PIN_SUCCESS,
    data,
  };
}

export function setPinFail(error) {
  return {
    type: SET_PIN_FAIL,
    error,
  };
}

export function forgotPasswordReq() {
  return {
    type: FORGOT_PASSWORD_REQUEST,
  };
}

export function forgotPasswordSuccess(data) {
  return {
    type: FORGOT_PASSWORD_SUCCESS,
    data,
  };
}

export function forgotPasswordFail(error) {
  return {
    type: FORGOT_PASSWORD_FAIL,
    error,
  };
}

export function resetLinkReq() {
  return {
    type: RESET_LINK_REQUEST,
  };
}

export function resetLinkSuccess(data) {
  return {
    type: RESET_LINK_SUCCESS,
    data,
  };
}

export function resetLinkFail() {
  return {
    type: RESET_LINK_FAIL,
  };
}

export function resetPasswordReq() {
  return {
    type: RESET_PASSWORD_REQUEST,
  };
}

export function resetPasswordSuccess(data) {
  return {
    type: RESET_PASSWORD_SUCCESS,
    data,
  };
}

export function resetPasswordFail(error) {
  return {
    type: RESET_PASSWORD_FAIL,
    error,
  };
}

export function logOutUserReq() {
  return {
    type: LOGOUT,
  };
}

export function clearMessagesReq() {
  return {
    type: CLEAR_MESSAGES,
  };
}

export function loadReq() {
  return {
    type: INITIAL_LOAD,
  };
}

export function adminFromTokenSuccess(data) {
  return {
    type: ADMIN_FROM_TOKEN_SUCCESS,
    isOwner: data.isOwner,
    data,
  };
}

export function userFromTokenReq() {
  return {
    type: USER_FROM_TOKEN_REQUEST,
  };
}

export function userFromTokenSuccess(data) {
  return {
    type: USER_FROM_TOKEN_SUCCESS,
    data,
  };
}

export function userFromTokenFail() {
  return {
    type: USER_FROM_TOKEN_FAILURE,
  };
}

export function changeSettingsReq() {
  return {
    type: CHANGE_SETTINGS_REQ,
  };
}

export function changeSettingsSuccess(data) {
  return {
    type: CHANGE_SETTINGS_SUCCESS,
    data,
  };
}

export function changeSettingsFail(error) {
  return {
    type: CHANGE_SETTINGS_FAIL,
    error,
  };
}

export function registerUserInvitationReq() {
  return {
    type: REGISTER_USER_INVITATION_REQUEST,
  };
}

export function registerUserInvitationSuccess() {
  return {
    type: REGISTER_USER_INVITATION_SUCCESS,
  };
}

export function registerUserInvitationFail() {
  return {
    type: REGISTER_USER_INVITATION_FAIL,
  };
}

export function userInviteCreateUserReq() {
  return {
    type: USER_INVITE_CREATE_USER_REQUEST,
  };
}

export function userInviteCreateUserSuccess() {
  return {
    type: USER_INVITE_CREATE_USER_SUCCESS,
  };
}

export function userInviteCreateUserFail() {
  return {
    type: USER_INVITE_CREATE_USER_FAIL,
  };
}

export function registerLinkReq() {
  return {
    type: REGISTER_LINK_REQUEST,
  };
}

export function registerLinkSuccess(data) {
  return {
    type: REGISTER_LINK_SUCCESS,
    data,
  };
}

export function registerLinkFail(error) {
  return {
    type: REGISTER_LINK_FAIL,
  };
}

export function registerLinkGenLink() {
  return {
    type: REGISTER_LINK_ACCESSED_VIA_GEN_LINK,
  };
}

export function fetchUserReq() {
  return {
    type: FETCH_USER_REQUEST,
  };
}

export function fetchUserSuccess(data) {
  return {
    type: FETCH_USER_SUCCESS,
    data,
  };
}

export function fetchUserFail(error) {
  return {
    type: FETCH_USER_FAIL,
    error,
  };
}

export function unsubscribeUserReq() {
  return {
    type: UNSUBSCRIBE_USER_REQUEST,
  };
}

export function unsubscribeUserSuccess() {
  return {
    type: UNSUBSCRIBE_USER_SUCCESS,
  };
}

export function unsubscribeUserFail(error) {
  return {
    type: UNSUBSCRIBE_USER_FAIL,
    error,
  };
}

export function fetchDirectoryReq() {
  return {
    type: FETCH_DIRECTORY_REQUEST,
  };
}

export function fetchDirectorySuccess(data) {
  return {
    type: FETCH_DIRECTORY_SUCCESS,
    data,
  };
}

export function fetchDirectoryFail(error) {
  return {
    type: FETCH_DIRECTORY_FAIL,
    error,
  };
}

export function vacationHoldRequestReq() {
  return {
    type: VACATION_HOLD_REQUEST_REQUEST,
  };
}

export function vacationHoldRequestSuccess(data) {
  return {
    type: VACATION_HOLD_REQUEST_SUCCESS,
    data,
  };
}

export function vacationHoldRequestFail(error) {
  return {
    type: VACATION_HOLD_REQUEST_FAIL,
    error,
  };
}

export function vacationListRequestSuccess(data) {
  return {
    type: VACATION_LIST_REQUEST_SUCCESS,
    data,
  };
}

export function vacationListRequestFail(error) {
  return {
    type: VACATION_LIST_REQUEST_FAIL,
    error,
  };
}

// Action Creators
export const onNavToggle = (input) => (dispatch) => {
  let data;
  if (input === 'on') {
    data = true;
  }
  if (input === 'off') {
    data = false;
  }
  if (!input) {
    data = 'toggle';
  }
  dispatch({
    type: NAV_TOGGLE,
    data,
  });
};

export const loginAdmin = (user, history) => (dispatch) => {
  dispatch(loginAdminReq());
  axios
    .post('/users/login_admin', user)
    .then(({ data }) => {
      localStorage.setItem('user_token', data.token);
      dispatch(loginAdminSuccess(data));
      history.push('/admin');
    })
    .catch((err) => {
      dispatch(loginAdminFail(err.response.data));
    });
};

export const loginUser = (user, history) => (dispatch) => {
  dispatch(loginUserReq());
  axios
    .post('/users/login_user', user)
    .then(({ data }) => {
      if (data.invitationToken) {
        let { status } = data;
        if (status === 'set_pin') {
          status = 'setpin';
        }
        return history.push(`/dashboard/${status}/${data.invitationToken}`);
      }

      localStorage.setItem('user_token', data.token);
      if (data.isAdmin) {
        dispatch(loginAdminSuccess(data));
      } else {
        dispatch(loginUserSuccess(data));
      }

      return history.push('/dashboard');
    })
    .catch((err) => {
      dispatch(
        warning(loginError(err.response ? err.response.data.message : err))
      );
      return dispatch(loginUserFail());
    });
};

export const registerUser = (user, history) => (dispatch) => {
  // make copy so original cannot be changed
  dispatch(logOutUser());
  const _user = { ...user };
  if (!_user.phone) {
    return dispatch(warning(emptyPhone));
  }

  if (!_user.time_zone) {
    return dispatch(warning(emptyTimezone));
  }

  if (!isValidNumber(_user.phone)) {
    return dispatch(warning(invalidPhone));
  }
  // if user doesn't have key for email_notification, then it defaults to true
  // blame redux form >:(
  if (!_user.hasOwnProperty('email_notification')) {
    _user.email_notification = true;
  }

  dispatch(registerUserReq());
  const token = history.location.pathname.split('/').pop();

  axios
    .post(`/users/register/${token}`, _user)
    .then(({ data }) => {
      dispatch(registerUserSuccess(data));
      history.push(`/dashboard/verify/${token}`);
    })
    .catch((err) => {
      if (err.response.status === 401) {
        dispatch(registerUserFail(err.response.data));
        return history.push('/dashboard/tokenExpires');
      }
      dispatch(registerUserFail(err.response.data.message));
    });
};

export const resendVerification = (phone, history) => (dispatch) => {
  const token = history.location.pathname.split('/').pop();

  axios
    .post(`/users/resendVerification`, { token })
    .then(({ data }) => {
      if (data.success) {
        dispatch(success(resendVerificationSuccess(phone)));
      } else {
      }
    })
    .catch((err) => {});
};

export const verifyUser = (verification_code, history) => (dispatch) => {
  dispatch(registerUserReq());
  const token = history.location.pathname.split('/').pop();

  axios
    .post(`/users/verifyUser/${token}`, verification_code)
    .then(({ data }) => {
      if (data.success) {
        dispatch(registerUserSuccess(data));
        dispatch(success(verifyUserSuccess));
        history.push(`/dashboard/setpin/${token}`);
      } else {
        // error
        // dispatch(warning(verifyUserFail));
      }
    })
    .catch((err) => {
      dispatch(registerUserFail(err.response.data));
      // dispatch(warning(verifyUserFail));
    });
};

export const setPin = (pin, history) => (dispatch) => {
  dispatch(setPinReq());
  const token = history.location.pathname.split('/').pop();

  axios
    .post(`/users/setPin/${token}`, pin)
    .then(({ data }) => {
      //localStorage.setItem('user_token', data.token);
      // dispatch(success(setPinNotiSuccess));
      dispatch(setPinSuccess(data));
      return history.push('/dashboard/login');
      // if (data.isOwner) {
      //   console.log("pretty sure this doesn't make it");
      //   dispatch(loginAdminSuccess(data));
      //   return history.push('/admin/invitation');
      // } else {

      // }
    })
    .catch((err) => {
      // dispatch(setPinFail(err));
      return history.push('/dashboard/login');
    });
};

export const forgotPassword = (email, history) => (dispatch) => {
  dispatch(forgotPasswordReq());

  axios
    .post('/users/forgot', email)
    .then(({ data }) => {
      if (data.data === 'No account with that email address exists.') {
        dispatch(warning(forgotPasswordFailMsg));
        dispatch(forgotPasswordFail(data));
        return;
      }
      dispatch(forgotPasswordSuccess(data));
    })
    .catch((err) => {
      if (err.response.status === 401) {
        dispatch(logOutUser());
        return history.push('/dashboard/login');
      }
      dispatch(forgotPasswordFail(err.response.data));
    });
};

export const resetLink = (token, history) => (dispatch) => {
  dispatch(resetLinkReq());

  axios
    .get(`/users/reset/${token}`)
    .then(({ data }) => {
      if (data === 'Password reset token is invalid or has expired.') {
        dispatch(resetLinkFail());
        dispatch(warning(tokenExpires));
        return history.push('/dashboard');
      }
      dispatch(resetLinkSuccess(data));
    })
    .catch((err) => dispatch(resetLinkFail()));
};

export const resetPassword = (password, history) => (dispatch) => {
  dispatch(forgotPasswordReq());
  const token = history.location.pathname.split('/').pop();

  axios
    .post(`/users/reset/${token}`, password)
    .then(({ data }) => {
      if (data === 'Password reset token is invalid or has expired.') {
        dispatch(warning(tokenExpires));
      } else if (data === 'Unable to save the new password!') {
        dispatch(warning(databaseError));
      } else {
        dispatch(success(passwordSuccessMsg));
        dispatch(resetPasswordSuccess(data));
        return history.push('/dashboard/login');
      }
    })
    .catch((err) => dispatch(forgotPasswordFail(err.response.data)));
};

export const logOutUser = (history) => (dispatch) => {
  try {
    dispatch(logOutUserReq());
    localStorage.removeItem('user_token');
  } catch (err) {
    console.error(`Logout error: ${err}`);
  }
};

export const clearMessages = () => (dispatch) => {
  dispatch(clearMessagesReq());
};

export const loadApp = () => (dispatch) => {
  dispatch(loadReq());
};

export const loadUserFromToken = () => (dispatch) => {
  const token = localStorage.getItem('user_token');
  if (!token || token === '') {
    return;
  }

  let tokenData = new FormData();

  tokenData.append('token', token);

  dispatch(userFromTokenReq());

  delete axios.defaults.headers.common['Authorization'];
  axios
    .post('/users/verifyToken', tokenData)
    .then(({ data }) => {
      if (data) {
        localStorage.setItem('user_token', data.newToken);
        if (data.isAdmin) {
          dispatch(adminFromTokenSuccess(data));
        } else {
          dispatch(userFromTokenSuccess(data));
        }
      } else {
        localStorage.removeItem('user_token');
        dispatch(userFromTokenFail());
      }
    })
    .catch(() => {
      dispatch(userFromTokenFail());
    });
};

export const changeSettings = (values, history) => (dispatch) => {
  const token = localStorage.user_token;
  if (!token) {
    dispatch(changeSettingsFail());
    return;
  }
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(changeSettingsReq());
  axios
    .post(
      values.password
        ? '/users/changePassword'
        : values.pin_password
        ? '/users/changePin'
        : '/users/changeSettings',
      values
    )
    .then(({ data }) => {
      localStorage.setItem('user_token', data.newToken);
      dispatch(success(settingsSuccess));
      dispatch(changeSettingsSuccess(data));
      return history.push('/admin/dashboard/settings');
    })
    .catch((err) => {
      if (err.response.status === 401) {
        dispatch(logOutUser());
        return history.push('/dashboard/login');
      }
      dispatch(changeSettingsFail(err.response.data));
      dispatch(warning(loginError(err.response.data.message)));
    });
};

export const registerUserInvitation = (token) => (dispatch) => {
  dispatch(registerUserInvitationReq());

  axios
    .get(`/users/invitation/${token}`)
    .then(({ data }) => {
      dispatch(registerUserInvitationSuccess());
    })
    .catch((err) => dispatch(registerUserInvitationFail()));
};

// create user from gen link
export const userInviteCreateUser = (fields, token) => (dispatch) => {
  dispatch(userInviteCreateUserReq());
  axios
    .post(`/users/invitation/${token}`, fields)
    .then(({ data }) => {
      dispatch(userInviteCreateUserSuccess());
    })
    .catch((err) => dispatch(userInviteCreateUserFail()));
};

export const registerLink = (token, history) => (dispatch) => {
  dispatch(registerLinkReq());

  axios
    .get(`/users/register/${token}`)
    .then(({ data }) => {
      if (data === 'Invalid token') {
        dispatch(registerLinkFail());
        dispatch(warning(tokenExpires));
        return history.push('/dashboard');
      } else if (data.phone) {
        if (!history.location.pathname.includes('/dashboard/verify')) {
          dispatch(registerLinkFail());
          return history.push(`/dashboard/verify/${token}`);
        }
      } else if (data === 'Valid Set Pin Token') {
        if (!history.location.pathname.includes('/dashboard/setpin')) {
          dispatch(registerLinkFail());
          return history.push(`/dashboard/setpin/${token}`);
        }
      } else if (
        data === 'Valid Token' &&
        !history.location.pathname.includes('/dashboard/register')
      ) {
        return history.push(`/dashboard/register/${token}`);
      } else if (
        data === 'Valid URL Pending Token' &&
        history.location.pathname.includes('/dashboard/register')
      ) {
        dispatch(registerLinkGenLink());
      }
      dispatch(registerLinkSuccess(data));
    })
    .catch((err) => dispatch(registerLinkFail()));
};

export const fetchUser = (user_id, history) => (dispatch) => {
  dispatch(fetchUserReq());
  axios
    .get(`/users/fetch/${user_id}`)
    .then(({ data }) => {
      if (!data.user.email) {
        dispatch(fetchUserFail());
        dispatch(warning(noUser));
        return history.push('/dashboard');
      }
      dispatch(fetchUserSuccess(data));
    })
    .catch((err) => {
      dispatch(fetchUserFail(err.response.data));
    });
};

export const unsubscribeUser = (user_id, history) => (dispatch) => {
  dispatch(unsubscribeUserReq());
  axios
    .post(`/users/unsubscribe`, { user_id })
    .then(() => {
      dispatch(unsubscribeUserSuccess());
      dispatch(success(unsubscribeSuccess));
      return history.push('/dashboard');
    })
    .catch((err) => {
      dispatch(unsubscribeUserFail(err.response.data));
    });
};

export const noValuesChangedAlert = () => (dispatch) => {
  dispatch(warning(noValuesChanged));
};

export const fetchDirectory = (history) => (dispatch) => {
  const token = localStorage.user_token;
  if (!token) {
    dispatch(fetchDirectoryFail());
    return;
  }
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(fetchDirectoryReq());
  axios
    .get('/users/fetchDirectory')
    .then(({ data }) => {
      dispatch(fetchDirectorySuccess(data));
    })
    .catch((err) => {
      if (err.response.status === 401) {
        dispatch(logOutUser());
        return history.push('/dashboard/login');
      }
      dispatch(fetchDirectoryFail(err.response.data));
    });
};

export const vacationHoldRequest = (sys_id, hold_from, hold_to, history) => (
  dispatch
) => {
  const token = localStorage.user_token;
  const vacationInfo = {
    sys_id,
    hold_from,
    hold_to,
    created_at: moment().format(),
  };

  if (!token) {
    dispatch(vacationHoldRequestFail());
    return;
  }

  if (!hold_from || !hold_to) {
    dispatch(vacationHoldRequestFail());
    return dispatch(warning(vacationHoldReqFail));
  }

  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  dispatch(vacationHoldRequestReq());

  axios
    .post('/users/vacation-hold', vacationInfo)
    .then((res) => {
      if (
        res.data ===
        'Vacation duration exceeds the maximum days of the system setting.'
      ) {
        dispatch(vacationHoldRequestFail());
        return dispatch(warning(vacationHoldExceedLimit));
      }

      if (res.status === 201) {
        dispatch(vacationHoldRequestSuccess(res.data));
        dispatch(success(vacationHoldReq));
      }

      return history.push('/dashboard/vacation');
    })
    .catch((err) => {
      if (err.response.status === 401) {
        dispatch(logOutUser());
        return history.push('/dashboard/login');
      }
      dispatch(vacationHoldRequestFail(err.response ? err.response.data : err));
    });
};
