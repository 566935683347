import axios from 'axios';
const ENV = process.env.REACT_APP_ENV || 'development';
let URL = process.env.REACT_APP_SERVER;

if (ENV === 'development') {
  URL = 'http://localhost:4000';
}

export const axiosInstance = axios.create({
  baseURL: URL,
});
