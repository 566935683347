import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { loadUserFromToken, loadApp } from 'redux/modules/duck-auth';
import Intercom from 'react-intercom';
import Admin from './Admin';
import Landing from './Landing';
import Dashboard from './Dashboard';
import AcceptInvite from './Admin/AcceptInvite';
import LoaderPage from 'common/LoaderPage';

class App extends Component {
  componentWillMount() {
    // logout user if user clicked on registration form link
    if (
      (this.props.location.pathname.includes('/dashboard/register') ||
        this.props.location.pathname.includes('/dashboard/reset')) &&
      localStorage.user_token
    ) {
      localStorage.removeItem('user_token');
    }

    // Load user if there is a token and user has not been authenticated
    if (!this.props.isAuthenticated && localStorage.user_token) {
      this.props.loadUserFromToken();
      // if unsubscribe linked is clicked and user still has a token logged in, redirect to settings page instead
      if (this.props.location.pathname.includes('/dashboard/unsubscribe')) {
        this.props.history.push('/admin/dashboard/settings/notifications');
      }
    } else {
      this.props.loadApp();
    }
  }

  renderAdmin() {
    const {
      isAuthenticated,
      initialLoad,
      isAdmin,
      isOwner,
      firstName,
      user,
    } = this.props;

    if (!isAuthenticated && initialLoad) {
      return <LoaderPage color="accent1" />;
    }
    if (isAuthenticated && isAdmin && !initialLoad) {
      return (
        <Admin isAdmin={isAdmin} isOwner={isOwner} firstName={firstName} />
      );
    }

    // if user does not belong here return REDIRECT(not an admin or not authenticated)
    return <Redirect to="/" />;
  }

  renderPage() {
    const { isAuthenticated, initialLoad, isAdmin, firstName } = this.props;
    if (!isAuthenticated && initialLoad) {
      return <LoaderPage color="accent1" />;
    }
    if (isAuthenticated && !initialLoad) {
      this.props.history.push('/admin/dashboard');
      return this.renderAdmin();
    }
    if (!isAuthenticated && !initialLoad) {
      return <Landing />;
    }
  }

  render() {
    const { isAuthenticated, user } = this.props;
    let info;
    if (user && user._id) {
      info = {
        user_id: user._id,
        email: user.email,
        name: `${user.first_name + ' ' + user.last_name}`,
      };
    }

    return (
      <div style={{ width: '100%' }}>
        <Switch>
          <Route path="/admin" render={() => this.renderAdmin()} />
          <Route path="/dashboard" render={() => this.renderPage()} />
          <Route path="/acceptInvite/:invite_token" component={AcceptInvite} />
          <Redirect path="/" to="/dashboard" />
        </Switch>
        {isAuthenticated && info ? (
          <Intercom appID={process.env.REACT_APP_INTERCOM_API_ID} {...info} />
        ) : null}
      </div>
    );
  }
}

App.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
};

function mapStateToProps({ auth }) {
  return {
    isAuthenticated: auth.isAuthenticated,
    initialLoad: auth.initialLoad,
    isAdmin: auth.isAdmin,
    isOwner: auth.isOwner,
    firstName: auth.user
      ? auth.user.first_name[0].toUpperCase() +
        auth.user.last_name[0].toUpperCase()
      : null,
    user: auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ loadUserFromToken, loadApp }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
