import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {} from 'redux/modules/duck-admin';

import ManageUserForm from './ManageUserForm';
import Title from 'common/forms/FormTitle';
import { Header } from 'common/Header';

class ManageUser extends Component {
  render() {
    const { userEdit, currentRole } = this.props;
    if (userEdit) {
      var fullName = `${userEdit.first_name} ${userEdit.last_name}`;
    }

    return (
      <Header style={{ marginTop: '25px' }}>
        {userEdit ? (
          <React.Fragment>
            <Title>Edit User: {fullName}</Title>
            <ManageUserForm userEdit={userEdit} currentRole={currentRole} />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Title>Add A User</Title>
            <ManageUserForm currentRole={currentRole} />
          </React.Fragment>
        )}
      </Header>
    );
  }
}

function mapStateToProps({ auth, admin, form }) {
  const { owner_systems, selectedSystem } = admin;
  return {
    auth,
    owner_systems,
    selectedSystem,
    form,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageUser);
