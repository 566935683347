import _ from 'lodash';

import { registerFields, inviteUserFields } from 'common/forms/formFields';

// eslint-disable-next-line
const email_re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validateEmail = (email) => {
  if (email_re.test(email.trim()) === false) {
    return 'Invalid Email';
  }
};

function validatePassword(password) {
  // Minimum eight characters, at least one letter, one number.
  const re = /^(?=.*\d)(?=.*[a-z])[0-9a-zA-Z!@#\$%\^\&*\)\(+=._-]{8,}$/;
  if (!re.test(password)) {
    return 'Password must be a minimum eight characters, at least one letter, and one number.';
  }
}

function validateZip(zip) {
  // Keep the regex here for US Postal Code
  // const re = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
  // if (re.test(zip.trim()) === false) {
  //   return 'Zip code is invalid';
  // }

  if (zip.length < 4 || zip.length > 10) {
    return 'Zip code is invalid';
  }
}

export const loginValidate = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = 'You must provide a email';
  }

  if (values.email && validateEmail(values.email)) {
    errors.email = validateEmail(values.email);
  }

  if (!values.password) {
    errors.password = 'You must provide a password';
  }

  return errors;
};

export const registerValidate = (values) => {
  const errors = {};
  if (values.confirm && values.password !== values.confirm) {
    errors.confirm = 'Please make sure your passwords match.';
  }

  errors.password = validatePassword(values.password || '');

  if (!values.first_name) {
    errors.first_name = 'You must provide a name';
  }
  if (!values.last_name) {
    errors.last_name = 'You must provide a name';
  }
  if (!values.phone) {
    errors.phone = 'You must provide a phone number';
  }
  if (!values.password || !values.confirm) {
    errors.confirm = 'You must provide a password';
  }

  return errors;
};

export const inviteUserValidate = (values) => {
  const errors = {};
  let emails = values.emails || '';
  emails = emails.split(',').map((email) => email.trim());

  for (const email of emails) {
    errors.emails = validateEmail(email || '');
  }

  _.each(inviteUserFields, ({ name }) => {
    if (!values[name]) {
      errors[name] = 'You must provide a value';
    }
  });

  return errors;
};

export const invitationValidate = (values) => {
  const errors = {};
  let emails = values.emails || '';
  emails = emails.split(',').map((email) => email.trim());

  for (const email of emails) {
    errors.emails = validateEmail(email || '');
  }

  _.each(inviteUserFields, ({ name }) => {
    if (name === 'emails' && !values[name]) {
      errors[name] = 'You must provide a value';
    }
  });

  return errors;
};

export const forgotPasswordValidate = (values) => {
  const errors = {};

  errors.email = validateEmail(values.email || '');

  _.each(registerFields, ({ name }) => {
    if (!values[name]) {
      errors[name] = 'You must provide a value';
    }
  });

  return errors;
};

export const resetPasswordValidate = (values) => {
  const errors = {};

  if (values.confirm && values.password !== values.confirm) {
    errors.confirm = 'Please make sure your passwords match.';
  }

  if (!values.password || !values.confirm) {
    errors.confirm = 'You must provide a password';
  }

  errors.password = validatePassword(values.password || '');

  return errors;
};

export const userInvitationValidate = ({ firstName, lastName, email }) => {
  const errors = {};

  const emailValidation = (value) =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
      ? 'Invalid email address'
      : undefined;

  if (emailValidation(email)) errors.email = 'Invalid email address';
  if (!firstName) errors.firstName = 'Required';
  if (!lastName) errors.lastName = 'Required';
};

export const settingsValidate = (values) => {
  const errors = {};

  errors.email = validateEmail(values.email || '');
  errors.secondary_email = validateEmail(values.secondary_email || '');

  if (values.confirm && values.password && values.password !== values.confirm) {
    errors.confirm = 'Please make sure your passwords match.';
  }

  if (!values.current_password) {
    errors.current_password = 'Please fill in your current password.';
  }
  if (!values.password) {
    errors.password = 'Please fill in all inputs.';
  }
  if (!values.confirm) {
    errors.confirm = 'Please fill in all inputs.';
  }

  if (!values.first_name) {
    errors.first_name = 'Please fill in all inputs.';
  }
  if (!values.last_name) {
    errors.last_name = 'Please fill in all inputs.';
  }

  if (!values.email) {
    errors.email = 'Please fill in a valid email.';
  }

  if (!values.secondary_email) {
    errors.secondary_email = null;
  }

  if (!values.phone) {
    errors.phone = 'Please fill in a valid phone number.';
  }

  return errors;
};

export const userInfoSettings = (values) => {
  const errors = {};

  if (values.first_name === '') {
    errors.first_name = 'Please check if first name is more than 1 letter.';
  }
  if (values.last_name === '') {
    errors.last_name = 'Please check if last name is more than 1 letter.';
  }

  if (!values.email === '') {
    errors.email = 'Please check if email is valid';
  }

  if (values.secondary_email && values.secondary_email.length < 5) {
    errors.secondary_email = 'Please check if email is valid';
  }

  return errors;
};

export const userPasswordValidation = (values) => {
  const errors = {};
  if (values.password && values.confirm && values.password !== values.confirm) {
    errors.confirm = 'Passwords must match';
  }
  if (
    values.password &&
    values.confirm &&
    !values.current_password &&
    values.password === values.confirm
  ) {
    errors.current_password = 'Required';
  }
  return errors;
};

export const systemSettingsValidation = (values) => {
  const errors = {};
  if (values.zip !== undefined) {
    errors.zip = validateZip(values.zip);
  }
  if (values.email !== undefined) {
    errors.email = validateEmail(values.email);
  }

  if (values.nickname === '') {
    errors.nickname = 'Please fill a system nickname.';
  }
  if (values.nickname && values.nickname.length > 25) {
    errors.nickname = 'Maximum length is 25 characters.';
  }
  if (values.courier_code === '') {
    errors.courier_code = 'Please fill a courier access code.';
  }
  if (values.address1 === '') {
    errors.address1 = 'Please fill an address.';
  }
  if (values.city === '') {
    errors.city = 'Please fill a city name';
  }
  if (values.zip === '') {
    errors.zip = 'Please fill a zip code';
  }
  if (values.email === '') {
    errors.email = 'Please fill an email';
  }
  if (values.phone === '') {
    errors.phone = 'Please fill a contact number';
  }

  return errors;
};

export const validateEmails = (values) => {
  const errors = {};

  if (
    (!values.emails || (values.emails && values.emails.length == 0)) &&
    (values.emails_csv == undefined || values.emails_csv.length == 0)
  ) {
    errors.emails = { _error: 'At least one email must be entered' };
  } else {
    const emailsArrayErrors = [];

    // we allow empty inputs but there must be atleast one email sent
    let oneValidEmail = false;

    if (values.emails.length > 0) {
      values.emails.forEach((email, emailIndex) => {
        if (email === undefined) {
          email = '';
        }
        let emailErrors;
        if (validateEmail(email) && email.length > 0) {
          emailsArrayErrors[emailIndex] = validateEmail(email);
        } else if (email != '' && email != undefined) {
          oneValidEmail = true;
        }
        return emailErrors;
      });

      if (emailsArrayErrors.length) {
        errors.emails = emailsArrayErrors;
      } else if (
        !oneValidEmail &&
        (values.emails_csv == undefined || values.emails_csv.length == 0)
      ) {
        errors.emails = { _error: 'At least one valid email must be entered' };
      }
    }
  }

  return errors;
};

export const verifyCode = (values) => {
  const errors = {};

  if (!values.verification_code) {
    errors.verification_code = 'Invalid Code';
  }

  if (values.verification_code && values.verification_code.length !== 6) {
    errors.verification_code = 'Invalid Code';
  }

  return errors;
};

export const setPin = (values) => {
  const errors = {};

  if (!values.pin) {
    errors.pin = 'Invalid Pin';
  }

  if (values.pin && values.pin.length !== 4) {
    errors.pin = 'Invalid Pin';
  }

  return errors;
};
