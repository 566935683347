export const registerFields = [
  [
    {
      label: 'First Name',
      name: 'first_name',
      type: 'text',
      placeholder: 'First Name',
    },
    {
      label: 'Last Name',
      name: 'last_name',
      type: 'text',
      placeholder: 'Last Name',
    },
  ],
  {
    label: 'Create Password',
    name: 'password',
    type: 'password',
    placeholder: 'Create Password',
  },
  {
    label: 'Confirm Password',
    name: 'confirm',
    type: 'password',
    placeholder: 'Confirm Password',
  },
];

export const inviteUserFields = [
  {
    type: 'textarea',
    name: 'emails',
    placeholder: "Add users' email addresses, separated by commas",
  },
];

export const invitationFields = [
  {
    type: 'textarea',
    name: 'emails',
    placeholder: "Add users' email addresses, separated by commas",
  },
];

export const notiSwitches = [
  { label: 'SMS Notification', name: 'sms_notification', type: 'checkbox' },
  { label: 'Email Notification', name: 'email_notification', type: 'checkbox' },
  { label: 'ADA Compliant', name: 'ada', type: 'checkbox' },
];

export const loginFields = [
  { label: 'email', name: 'email', type: 'text', placeholder: 'Email' },
  {
    label: 'Password',
    name: 'password',
    type: 'password',
    placeholder: 'Password',
  },
];

export const forgotPasswordFields = [
  { label: 'Email', name: 'email', type: 'text', placeholder: 'Email' },
];

export const resetPasswordFields = [
  {
    label: 'New Password',
    name: 'password',
    type: 'password',
    placeholder: 'Password',
  },
  {
    label: 'Confirm Password',
    name: 'confirm',
    type: 'password',
    placeholder: 'Confirm Password',
  },
];

export const settingsFields = {
  name: [
    { label: 'First Name', name: 'first_name', type: 'text' },
    { label: 'Last Name', name: 'last_name', type: 'text' },
  ],
  email: [{ label: 'Email', name: 'email', type: 'text' }],
  secondary_email: [
    { label: 'Secondary Email', name: 'secondary_email', type: 'text' },
  ],
  password: [
    { label: 'Current Password', name: 'current_password', type: 'password' },
    { label: 'New Password', name: 'password', type: 'password' },
    { label: 'Confirm Password', name: 'confirm', type: 'password' },
  ],
  phone: [{ label: 'Phone', name: 'phone', type: 'text' }],
  timezone: [{ label: 'Timezone', name: 'timezone' }],
  notifications: [
    { label: 'Dropoff Notification', name: 'dropoff', type: 'checkbox' },
    { label: 'Picked Up Notification', name: 'picked_up', type: 'checkbox' },
    {
      label: 'Expiring Package Reminder',
      name: 'expiring',
      type: 'checkbox',
    },
    { label: 'Pick Up Reminder', name: 'pickup_reminder', type: 'checkbox' },
    {
      label: 'Secondary Email Notification',
      name: 'notifications.secondary_email_notification',
      type: 'checkbox',
    },
  ],
};

export const editProfileFields = [
  { label: 'Email', name: 'email', type: 'text' },
  [
    { label: 'First Name', name: 'first_name', type: 'text' },
    { label: 'Last Name', name: 'last_name', type: 'text' },
  ],
  { label: 'Secondary Email', name: 'secondary_email', type: 'text' },
];

export const systemSettingsFields = [
  { label: 'System Name', name: 'nickname', type: 'text' },
  [
    { label: 'Address Line 1', name: 'address1', type: 'text' },
    { label: 'Address Line 2', name: 'address2', type: 'text' },
  ],
  [
    { label: 'City', name: 'city', type: 'text' },
    { label: 'State', name: 'state', type: 'text' },
    { label: 'Zip Code', name: 'zip', type: 'text' },
  ],
  [
    { label: 'Email', name: 'email', type: 'text' },
    { label: 'Contact Number', name: 'phone', type: 'text' },
  ],
];

export const vacationLimit = [
  { value: 0, label: 'Off' },
  { value: 1, label: '1 day' },
  { value: 2, label: '2 days' },
  { value: 3, label: '3 days' },
  { value: 4, label: '4 days' },
  { value: 5, label: '5 days' },
  { value: 6, label: '6 days' },
  { value: 7, label: '7 days' },
  { value: 8, label: '8 days' },
  { value: 9, label: '9 days' },
  { value: 10, label: '10 days' },
];

export const parcelExpiration = [
  { value: 1, label: '1 day' },
  { value: 2, label: '2 days' },
  { value: 3, label: '3 days' },
  { value: 4, label: '4 days' },
  { value: 5, label: '5 days' },
  { value: 6, label: '6 days' },
  { value: 7, label: '7 days' },
  { value: 8, label: '8 days' },
  { value: 9, label: '9 days' },
  { value: 10, label: '10 days' },
  { value: 15, label: '15 days' },
  { value: 30, label: '30 days' },
];

export const managerPickUpTime = [
  { value: 'Morning', label: 'Morning' },
  { value: 'End of Day', label: 'End of Day' },
  { value: 'Off', label: 'Off' },
];

export const userReminderFrequency = [
  { value: 'Two-Day', label: 'Two-Day' },
  { value: 'Three-Day', label: 'Three-Day' },
  { value: 'Daily', label: 'Daily' },
  { value: 'Off', label: 'Off' },
];

export const timezones = [
  {
    value: 'US/Hawaii',
    label: 'Hawaii Standard Time (HST GMT-10:00)',
    key: 0,
  },
  {
    value: 'US/Alaska',
    label: 'Alaska Standard Time (AST GMT-9:00)',
    key: 1,
  },
  {
    value: 'America/Los_Angeles',
    label: 'Pacific Standard Time (PST GMT-8:00)',
    key: 2,
  },
  {
    value: 'America/Denver',
    label: 'Mountain Standard Time (MST GMT-7:00)',
    key: 3,
  },
  {
    value: 'America/Phoenix',
    label: 'Phoenix Standard Time (PNT GMT-7:00)',
    key: 4,
  },
  {
    value: 'America/Chicago',
    label: 'Central Standard Time (CST GMT-6:00)',
    key: 5,
  },
  {
    value: 'America/New_York',
    label: 'Eastern Standard Time (EST GMT-5:00)',
    key: 6,
  },
  {
    value: 'Etc/GMT-5',
    label: 'Indiana Eastern STandard Time (IET GMT-5:00)',
    key: 7,
  },
  {
    value: 'Etc/UTC',
    label: 'Universal Coordinated Time (UTC/GMT)',
    key: 8,
  },
  {
    value: 'Etc/GMT+1',
    label: 'European Central Time (ECT GMT+1:00)',
    key: 9,
  },
  {
    value: 'EET',
    label: 'Eastern European Time(EET GMT+2:00)',
    key: 10,
  },
  {
    value: 'Africa/Cairo',
    label: '(Arabic) Egypt Standard Time (ART GMT+2:00)',
    key: 11,
  },
  {
    value: 'Africa/Nairobi',
    label: 'Eastern African Time (EAT GMT+3:00)',
    key: 12,
  },
  {
    value: 'MET',
    label: 'Middle East Time (MET GMT+3:30)',
    key: 13,
  },
  {
    value: 'Etc/GMT+4',
    label: 'Near East Time (NET GMT+4:00)',
    key: 14,
  },
  {
    value: 'Etc/GMT+5',
    label: 'Pakistan Lahore Time (PLT GMT+5:00)',
    key: 15,
  },
  {
    value: 'Asia/Kolkata',
    label: 'India Standard Time (IST GMT+5:30)',
    key: 16,
  },
  {
    value: 'Etc/GMT+6',
    label: 'Bangladesh Standard Time (BST GMT+6:00)',
    key: 17,
  },
  {
    value: 'Etc/GMT+7',
    label: 'Vietnam Standard Time (VST GMT+7:00)',
    key: 18,
  },
  {
    value: 'Etc/GMT+8',
    label: 'China Taiwan Time (CTT GMT+8:00)',
    key: 19,
  },
  {
    value: 'Asia/Tokyo',
    label: 'Japan Standard Time (JST GMT+9:00)',
    key: 20,
  },
  {
    value: 'Australia/Adelaide',
    label: 'Australia Central Time (ACT GMT+9:30)',
    key: 21,
  },
  {
    value: 'Australia/Sydney',
    label: 'Australia Eastern Time (AET GMT+10:00)',
    key: 22,
  },
  {
    value: 'Etc/GMT+11',
    label: 'Solomon Standard Time (SST GMT+11:00)',
    key: 23,
  },
  {
    value: 'Etc/GMT+12',
    label: 'New Zealand Standard Time (NST GMT+12:00)',
    key: 24,
  },
  {
    value: 'Etc/GMT-11',
    label: 'Midway Islands Time (MIT GMT-11:00)',
    key: 25,
  },
  {
    value: 'America/Puerto_Rico',
    label: 'Puerto Rico and US Virgin Islands Time (PRT GMT-4:00)',
    key: 26,
  },
  {
    value: 'America/Argentina/Buenos_Aires',
    label: 'Argentina Standard Time (AGT GMT-3:00)',
    key: 27,
  },
  {
    value: 'Etc/GMT-3',
    label: 'Brazil Eastern Time (BET GMT-3:00)',
    key: 28,
  },
  {
    value: 'Africa/Juba',
    label: 'Central African Time (CAT GMT-1:00)',
    key: 29,
  },
];

export const notifOptions = [
  {
    value: 'None',
    label: 'None',
    key: 0,
  },
  { value: 'SMS', label: 'SMS', key: 1 },
  {
    value: 'EMAIL',
    label: 'EMAIL',
    key: 2,
  },
  {
    value: 'BOTH',
    label: 'BOTH SMS/EMAIL',
    key: 3,
  },
];
