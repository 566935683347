import React, { Component } from 'react';
import styled from 'styled-components';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  registerLink,
  setPin,
  resendVerification,
} from 'redux/modules/duck-auth';

import { Header } from 'common/Header';
import SetPinForm from './SetPinForm';
import Title from 'common/forms/FormTitle';
import LoaderPage from 'common/LoaderPage';

const NewHeader = styled(Header)`
  min-height: 518px;
  height: auto;
  border-radius: 4px;
  background-color: #f8f8f8;
  margin-top: 50px;
  @media (max-width: 768px) {
    width: 350px;
  }
`;

const SubTitle = styled.span`
  height: 42px;
  width: 306px;
  color: #083e66;
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
`;

class SetPin extends Component {
  componentDidMount() {
    const { history } = this.props;
    const { token } = this.props.match.params;

    this.props.registerLink(token, history);
  }

  render() {
    const { auth, form, history } = this.props;

    return auth.validToken ? (
      <NewHeader landing={true}>
        <SubTitle>Create 4 digit pin to receive packages.</SubTitle>
        <SetPinForm
          err={auth.error}
          loading={auth.loading}
          onPinSubmit={() => {
            this.props.setPin(form.setPinForm.values, history);
          }}
        />
      </NewHeader>
    ) : (
      <LoaderPage color="accent1" />
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      registerLink,
      setPin,
    },
    dispatch
  );
}

function mapStateToProps({ auth, form }) {
  return { auth, form };
}

export default connect(mapStateToProps, mapDispatchToProps)(SetPin);
