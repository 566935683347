import TimezoneDropdown from 'common/forms/dropdownMenu';
import styled, { css } from 'styled-components';

import colors from 'utils/colors';
import { mobile } from 'utils/media';

export default styled(TimezoneDropdown)`
  height: -10px;
  width: 275px;
  margin: 30px 0 0 40px;
  img {
    border: none;
    height: 14px;
    width: 27px;
  }

  select {
    margin-top: 15px;
  }

  button {
    border: none;
  }

  input {
    background-color: #d1d1d1;
    width: 100%;
    color: ${colors.base_text3};
    text-align: center;
    font-size: 16px;

    ::placeholder {
      font-size: 16px;
      color: ${colors.primary_gray};
      opacity: 1;
      transition: opacity 150ms ease-in-out;
    }

    ${(p) =>
      p.formtype !== 'register'
        ? mobile(
            css`
              padding-left: 5px;
              text-align: left;
            `
          )
        : null};
    + span {
      width: 80%;
      &:after {
        margin-top: -1.5px;
        z-index: 999;
        display: block;
        content: '';
        border-bottom: 2px solid
          ${(p) =>
            p.formtype === 'landing' || p.formtype === 'register'
              ? colors.base_text3
              : colors.base_text3};
        transform: scaleX(0);
        transition: transform 250ms ease-in-out;
      }
    }
  }

  input:focus {
    outline: none;
    + span {
      &:after {
        transform: scaleX(1.01);
      }
    }
    &::placeholder {
      opacity: 1;
    }
  }

  &::placeholder {
    font-size: 14px;
    color: ${colors.primary_gray};
    opacity: 0.75;
    transition: opacity 150ms ease-in-out;
  }
`;
