import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import moment from 'moment';

import {
  selectSystem,
  fetchCounters,
  fetchSystemLog,
} from 'redux/modules/duck-admin';

import { Header } from 'common/Header';
import LoaderPage from 'common/LoaderPage';
import SystemLogTable from 'common/SystemLogTable';

import SvgIcon from 'common/icons/SvgIcon';

import { mobile } from 'utils/media';
import colors from 'utils/colors';
import { baseFont, boldFont } from 'utils/fonts';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const SelectWrapper = styled.div`
  display: block;
  align-self: flex-start;
  width: 221px;
  margin-bottom: 50px;
`;

const SelectWrap = styled.div`
  position: relative;
  :after {
    content: '';
    position: absolute;
    top: 50%;
    right: 8px;
    margin-top: -3px;
    border-width: 6px;
    border-style: solid;
    border-color: ${colors.base_text3} transparent transparent;
  }
`;

const SelectField = styled.select`
  width: 100%;
  height: 25px;
  font-family: ${baseFont};
  font-size: 14px;
  font-weight: bold;
  color: ${colors.base_text3};
  background: white;
  border-radius: 3px;
  border-color: ${colors.base_text3};
  letter-spacing: 2px;
  text-transform: uppercase;
  line-height: 16px;
`;

const NA = styled.div`
  min-height: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: 30px;
  color: ${colors.primary_blue};
  font-family: ${boldFont};
`;

const DateTitle = styled.h1``;

const LogContainer = styled.tbody`
  display: flex;
  flex-direction: column;
  padding: 0;
  justify-content: space-between;
`;

const Log = styled.tr`
  margin: 5px;
`;

const Title = styled.h1`
  font-size: 24px;
  color: ${colors.base_text3};
  font-family: ${baseFont};
  line-height: 22px;
  font-weight: bold;
  margin-bottom: 50px;
  align-self: flex-start;
`;

const InputWrapper = styled.div`
  box-sizing: border-box;
  margin-bottom: 50px;
`;

const FilterBar = styled.input`
  float: left;
  width: 60%;
  height: 28px;
  padding-left: 40px;
  font-size: 18px;
  line-height: 21px;
  font-family: ${baseFont};
  border: none;
  border-bottom: 1px solid rgba(51, 51, 51, 0.3);
  transition: all 350ms ease-in-out;
  &::placeholder {
    font-size: 18px;
    line-height: 21px;
    font-family: ${baseFont};
    color: ${colors.primary_gray};
    opacity: 0.35;
    transition: opacity 150ms ease-in-out;
  }
  &:focus {
    outline: none;
    + span {
      &:after {
        width: 100%;
      }
    }
    &::placeholder {
      opacity: 0.1;
    }
  }
  + span {
    float: left;
    width: calc(60% + 40px);
    &:after {
      margin-top: -1px;
      z-index: 999;
      display: block;
      content: '';
      border-bottom: 2px solid ${colors.base_text3};
      width: 0;
      transition: width 250ms ease-in-out;
    }
  }
`;

class SystemLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTime: 'one_month',
      search: '',
    };
    this.handleFilter = this.handleFilter.bind(this);

    this.handleTimeSelect = this.handleTimeSelect.bind(this);
  }

  componentDidMount() {
    if (localStorage.user_token && this.props.selectedSystem) {
      this.props.fetchSystemLog(
        this.props.selectedSystem.system._id,
        'one_month',
        this.props.history
      );
    }
  }

  handleFilter(e) {
    const input_v = e.currentTarget.value.toLowerCase();
    this.setState({ search: input_v });
  }

  renderTimelineSelect() {
    return (
      <SelectWrapper>
        <SelectWrap>
          <SelectField onChange={this.handleTimeSelect}>
            <option value="one_month">Last Month</option>
            <option value="three_month">Last 3 Months</option>
            <option value="one_year">Last Year</option>
          </SelectField>
        </SelectWrap>
      </SelectWrapper>
    );
  }

  handleTimeSelect(e) {
    if (e.target.value !== this.state.selectedTime) {
      this.setState(
        {
          selectedTime: e.target.value,
        },
        () => {
          this.props.fetchSystemLog(
            this.props.selectedSystem.system._id,
            this.state.selectedTime,
            this.props.history
          );
        }
      );
    }
  }

  renderLog() {
    const { system_log, history_loading } = this.props;

    let filteredParcels = system_log;

    // Search feature
    if (this.state.search) {
      filteredParcels = system_log.filter((row) => {
        // Filter by full name
        if (row.first_name) {
          // Skip pending user by checking first name existence
          const fullName = `${row.first_name} ${row.last_name}`.toLowerCase();
          if (fullName.includes(this.state.search)) {
            return row;
          }
        }

        // Filter by courier
        // if (row.courier.includes(this.state.search)) {
        //   return row;
        // }
        if (row.pin.includes(this.state.search)) {
          return row;
        }

        if (row.status.toLowerCase().includes(this.state.search)) {
          return row;
        }

        if (row.status.includes(this.state.search)) {
          return row;
        }
      });
    }

    return (
      <SystemLogTable parcels={filteredParcels} log_loading={history_loading} />
    );
  }

  render() {
    const { systems, system_log, history_loading } = this.props;
    return (
      <Header>
        {system_log &&
        system_log !== null &&
        this.props.selectedSystem !== null ? (
          <Wrapper>
            <Title>History</Title>
            <InputWrapper>
              <SvgIcon
                height="18px"
                width="18px"
                fill={colors.base_text3}
                icon="search"
                viewBox="0 0 18 18"
                style={{
                  position: 'absolute',
                  float: 'left',
                  paddingTop: '5px',
                  left: '15px',
                }}
              />
              <FilterBar
                onChange={this.handleFilter}
                type="text"
                innerRef={(ref) => (this.filter = ref)}
                placeholder="Search"
              />
              <span />
            </InputWrapper>
            {this.renderTimelineSelect()}
            {this.renderLog()}
          </Wrapper>
        ) : (
          <div style={{ marginTop: '200px', height: '200px' }}>
            <LoaderPage color="accent1" height="50%" width="50%" />
          </div>
        )}
      </Header>
    );
  }
}

function mapStateToProps({ admin }) {
  const { systems, system_log, history_loading, selectedSystem } = admin;
  return {
    systems,
    system_log,
    history_loading,
    selectedSystem,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchCounters,
      selectSystem,
      fetchSystemLog,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SystemLog);
