import React, { Component } from 'react';
import styled from 'styled-components';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  registerLink,
  registerUser,
  clearMessages,
} from 'redux/modules/duck-auth';

import { Header } from 'common/Header';
import RegisterForm from './RegisterForm';
import LoaderPage from 'common/LoaderPage';
import ErrorMessage from 'common/Error';

const NewHeader = styled(Header)`
  min-height: 600px;
  height: auto;
  border-radius: 4px;
  background-color: #f8f8f8;
  margin-top: 50px;
`;

class Register extends Component {
  componentWillMount() {
    const { history } = this.props;
    const { token } = this.props.match.params;

    this.props.registerLink(token, history);
  }

  componentWillUnmount() {
    if (this.props.auth.error) {
      this.props.clearMessages();
    }
  }
  // if true, show text under notifcations, otherwise don't show it
  checkFormNotifValues = (form) => {
    if (!form || !form.registerForm || !form.registerForm.values) {
      return false;
    }
    const emailValue = () => {
      if (!form.registerForm.values.hasOwnProperty('email_notification')) {
        return true;
      } else if (!form.registerForm.values.email_notification) {
        return false;
      } else {
        return true;
      }
    };

    const smsValue = () => {
      if (!form.registerForm.values.hasOwnProperty('sms_notification')) {
        return false;
      } else if (!form.registerForm.values.sms_notification) {
        return false;
      } else {
        return true;
      }
    };
    if (!emailValue() && !smsValue()) {
      return true;
    }

    return false;
  };

  render() {
    const { auth, form, history } = this.props;
    return auth.validToken ? (
      <NewHeader landing={true}>
        <RegisterForm
          err={auth.error}
          loading={auth.loading}
          showName={!auth.accessedViaGenLink}
          showNotifText={this.checkFormNotifValues(form)}
          onRegisterSubmit={() => {
            this.props.registerUser(form.registerForm.values, history);
          }}
        />
        {auth.error && <ErrorMessage>{auth.error}</ErrorMessage>}
      </NewHeader>
    ) : (
      <LoaderPage color="accent1" />
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      registerLink,
      registerUser,
      clearMessages,
    },
    dispatch
  );
}

function mapStateToProps({ auth, form }) {
  return { auth, form };
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);
