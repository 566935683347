import React from 'react';

import SvgIcon from 'common/icons/SvgIcon';

import colors from 'utils/colors';

export default (props) => {
  const { step } = props;
  return (
    <g id="Phone-Hover-tip" transform="translate(-451.000000, -57.000000)">
      <g id="Registration-Steps" transform="translate(450.000000, 57.000000)">
        <rect
          id="Rectangle"
          fill="#FFFFFF"
          opacity="0.496675037"
          x="57"
          y="20"
          width="191.5"
          height="5"
          rx="2"
        />
        <rect
          id="Rectangle"
          fill="#FFFFFF"
          opacity="0.496675037"
          x="294"
          y="20"
          width="192"
          height="5"
          rx="2"
        />
        <g id="Set-Pin" transform="translate(478.333333, 0.000000)">
          <circle
            id="Oval"
            fill={step === 'Set-Pin' ? '#15234A' : '#F7F7F7'}
            opacity={step === 'Set-Pin' ? 1 : 0.4}
            cx="29.5"
            cy="22.5"
            r="22.5"
          />
          <text
            id="3"
            fontFamily="Swiss721BTRegular"
            fontSize="24"
            fontWeight="normal"
            fill="#FFFFFF"
          >
            <tspan x="23" y="30">
              3
            </tspan>
          </text>
          <text
            id="SET-PIN"
            opacity={step === 'Set-Pin' ? 1 : 0.55}
            fontFamily="Swiss721BTRegular"
            fontSize="16"
            fontWeight="normal"
            line-spacing="63"
            fill="#FFFFFF"
          >
            <tspan x="0.764322917" y="66">
              SET PIN
            </tspan>
          </text>
        </g>
        <g className="regisStep" id="Register">
          <g transform="translate(12.000000, 0.000000)">
            <circle
              id="Oval"
              fill={step === 'Register' ? '#15234A' : '#F7F7F7'}
              cx="22.5"
              cy="22.5"
              r="22.5"
              opacity={step === 'Register' ? 1 : 0.4}
            />
            {step === 'Register' ? (
              <text
                id="1"
                fontFamily="Swiss721BTRegular"
                fontSize="24"
                fontWeight="normal"
                fill="#FFFFFF"
              >
                <tspan x="16" y="30">
                  1
                </tspan>
              </text>
            ) : (
              <SvgIcon
                icon="checkmark-nocircle"
                height="42px"
                width="46px"
                fill={colors.white}
                viewBox="0 0 22 22"
              />
            )}
          </g>
          <text
            id="REGISTER"
            fontFamily="Swiss721BTRegular"
            fontSize="16"
            fontWeight="normal"
            line-spacing="63"
            fill={step === 'Register' ? '#FFFFFF' : '#F7F7F7'}
            opacity={step === 'Register' ? 1 : 0.4}
            margin-top="5px"
          >
            <tspan x="0.24609375" y="66">
              REGISTER
            </tspan>
          </text>
        </g>
        <g
          className="regisStep"
          id="Verify"
          transform="translate(245.000000, 0.000000)"
        >
          <text
            id="VERIFY"
            opacity="0.55"
            fontFamily="Swiss721BTRegular"
            fontSize="16"
            fontWeight="normal"
            line-spacing="63"
            fill={step === 'Verify' ? '#FFFFFF' : '#F7F7F7'}
            opacity={step === 'Verify' ? 1 : 0.4}
          >
            <tspan x="0.14453125" y="66">
              VERIFY
            </tspan>
          </text>
          <circle
            id="Oval"
            fill={step === 'Verify' ? '#15234A' : '#F7F7F7'}
            opacity={step === 'Verify' ? 1 : 0.4}
            cx="26.1666667"
            cy="22.5"
            r="22.5"
          />
          {step === 'Set-Pin' ? (
            <SvgIcon
              icon="checkmark-nocircle"
              height="42px"
              width="52px"
              fill={colors.white}
              viewBox="0 0 22 22"
            />
          ) : (
            <text
              id="2"
              fontFamily="Swiss721BTRegular"
              fontSize="24"
              fontWeight="normal"
              fill="#FFFFFF"
            >
              <tspan x="19.6666667" y="30">
                2
              </tspan>
            </text>
          )}
        </g>
      </g>
    </g>
  );
};
